/* eslint-disable import/first */
import React from "react";

import {
    Users,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards

// Auth components
import AuthGuard from '../components/AuthGuard';
import AuthLoader from '../components/AuthLoader';
import Page404 from '../pages/auth/Page404';
import Page500 from '../pages/auth/Page500';

// Admin routes
import { Add, Event } from '@material-ui/icons';
import EventListPage from '../pages/events/List';
import { RouteType } from '../types/routes';
import EventCreatePage from '../pages/events/Create';
import EventEditPage from '../pages/events/Edit';

const authRoutes: RouteType = {
    id: "Auth",
    path: "/auth",
    icon: <Users />,
    children: [
        {
            path: "/auth/403",
            name: "Auth",
            component: AuthLoader,
        },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404,
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500,
        },
    ],
    component: null,
};

const eventSideBarRoute: RouteType = {
    header: "",
    id: "Events",
    path: "/",
    icon: <Event />,
    component: EventListPage,
    children: null
}

const eventsRoutes: RouteType = {
    ...eventSideBarRoute,
    children: [
        {
            name: "All events",
            path: "/",
            icon: <Event />,
            component: EventListPage,
            guard: AuthGuard
        },
        {
            name: "Create Event",
            path: "/events/create",
            icon: <Add />,
            component: EventCreatePage,
            guard: AuthGuard
        },
        {
            name: "Edit Event",
            path: "/events/:id",
            icon: <Add />,
            component: EventEditPage,
            guard: AuthGuard
        },
    ],
    guard: AuthGuard
}


// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
    eventsRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [
    eventsRoutes
];

// Routes that are protected
export const protectedRoutes = [
    eventsRoutes
];

// Routes visible in the sidebar
export const sidebarRoutes = [
    eventSideBarRoute,
];
