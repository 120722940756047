import React, { useEffect, useState } from 'react';
import { Alert, Button, Grid, Typography } from "../../../components/Common";
import { LiveStreamsDto } from '../../../apis/_generated/livestream';
import { LinearProgress } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import LiveStreamDialog, { SaveableLiveStream } from './LiveStreamDialog';
import LiveStreamListTable from './LiveStreamListTable';

interface EventLiveStreamListProps {
    eventId: number,
    liveStreams?: LiveStreamsDto[],
}

const LiveStreamList = (props: EventLiveStreamListProps) => {
    const blankLiveStream: SaveableLiveStream = {};
    const [editLiveStream, setEditLiveStream] = useState({});
    const [liveStreamList, setLiveStreamList] = useState<LiveStreamsDto[]>();

    useEffect(() => {
        setLiveStreamList(props.liveStreams);
    }, [props.liveStreams]);

    const [liveStreamsRefreshing, setLiveStreamsRefreshing] = useState(false);
    const [liveStreamsError, setLiveStreamsError] = useState('');
    const [liveStreamsSuccess, setLiveStreamsSuccess] = useState('');

    const [openDialog, setOpenDialog] = useState(false);
    // function to handle modal open
    const handleOpen = (liveStream: SaveableLiveStream) => {
        setEditLiveStream(liveStream);
        setOpenDialog(true);
    };
    // function to handle modal close
    const handleClose = () => {
        setEditLiveStream(blankLiveStream);
        setOpenDialog(false);
    };

    const updateLiveStreamList = (liveStreamsDto: LiveStreamsDto) => {
        if (!liveStreamList) {
            setLiveStreamList([liveStreamsDto]);
        } else {
            setLiveStreamsRefreshing(true);
            const editedLiveStreamIndex = liveStreamList.findIndex((o) => o.id === liveStreamsDto.id)
            if (-1 !== editedLiveStreamIndex) {
                liveStreamList[editedLiveStreamIndex] = liveStreamsDto;
            } else {
                // add new video
                liveStreamList.push(liveStreamsDto);
            }
            setLiveStreamList(liveStreamList.filter((o) => true));
        }

        setLiveStreamsRefreshing(false);
    }

    return (<Grid container spacing={3} id="videoList">
        <Grid item spacing={6} xs={12}>
            <Grid justify='space-between' container>
                <Grid item>
                    <Typography variant='h6' gutterBottom>
                        Live stream video setup
                    </Typography>
                </Grid>
                <Grid item>
                    {liveStreamList && !liveStreamList.length &&
                    <Button
                        startIcon={<AddIcon/>}
                        size='large'
                        variant='contained'
                        color='primary'
                        mb={3}
                        onClick={() => handleOpen(blankLiveStream)}>
                        New LiveStream
                    </Button>
                    }

                </Grid>
            </Grid>

        </Grid>
        {liveStreamsRefreshing && (
        <Grid item xs={12} paddingTop={4}>
            <LinearProgress color="secondary" />
        </Grid>
        )}
        {liveStreamsError && (
            <Grid item xs={12} paddingTop={4}>
                <Alert severity="error">{liveStreamsError}</Alert>
            </Grid>
        )}
        {liveStreamsSuccess && (
          <Grid item xs={12} paddingTop={4}>
              <Alert severity="success">{liveStreamsSuccess}</Alert>
          </Grid>
        )}

        {!liveStreamList || !liveStreamList.length ? null :
            <LiveStreamListTable
                livestreams={liveStreamList}
                handleOpen={handleOpen}
                handleError={setLiveStreamsError}
                handleSuccess={setLiveStreamsSuccess}
            />
        }

        <LiveStreamDialog
            eventId={props.eventId}
            liveStream={editLiveStream}
            open={openDialog}
            handleClose={handleClose}
            liveStreamList={liveStreamList}
            updateLiveStreamList={updateLiveStreamList}
        />
    </Grid>);
};

export default LiveStreamList;
