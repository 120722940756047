export const LiveStreamStatuses:  {
  type: boolean,
  label: string
}[] = [
  {
    type: false,
    label: 'No'
  },
  {
    type: true,
    label: 'Yes'
  },
];

export const getLiveStreamStatusLabel = (type: boolean): string => {
  const status = LiveStreamStatuses.find((item) => item.type === type) ?? LiveStreamStatuses[0];
  return status.label;
}
