import * as types from '../../constants';

enum ResourceServicesEnum {
  lapi = 'lapi',
  livestream = 'livestream',
}

type ConfigResourceInterface = {
  [key in ResourceServicesEnum]: {
    "endpoint": string;
  };
};

interface ConfigSsoInterface {
  profileUrl: string,
  logoutUrl: string
}

interface ConfigServicesInterface {
  apiVideo: { videoChunkSize: number, videoRetryAttempts: number }
}

export interface ConfigInterface {
  resources: ConfigResourceInterface,
  sso: ConfigSsoInterface,
  services: ConfigServicesInterface
}

export type SetConfigType = {
  type: typeof types.CONFIG_SET;
  payload: ConfigInterface;
};
export function setConfig(value: ConfigInterface): SetConfigType {
  return {
    type: types.CONFIG_SET,
    payload: value,
  };
}
