import {
    Checkbox,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    Link,
    ListItemIcon,
    MenuItem,
} from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CenteredContent,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Select,
    TextField,
    Typography
} from '../../components/Common';
import { EventCreateDto, EventOutDto, EventPatchDto, EventsShuttlelinksDto } from "../../apis/_generated/livestream";
import { useFormik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { App, Region, Station } from '../../apis/_generated/listenapi';
import { ListenApiService } from '../../services/listenApiService';
import { EventTypes } from "../../constants/eventTypes";
import { Color } from "@material-ui/lab/Alert/Alert";
import { EventStatuses } from '../../constants/eventStatuses';
import { EventManagerService } from '../../services/eventManagerService';
import CropDialog, { CropImageInterface } from '../../components/CropDialog';
import ImageUpload from '../../components/ImageUpload';
import VideoList from './videos/VideoList';
import slugify from 'slugify';
import { useSelector } from 'react-redux';
import { AppStateType } from '../../redux/reducers';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import moment from "moment-timezone";
import { DatePickerWithTimezone } from "../../components/Dates";
import ShuttleLinks from "../../components/ShuttleLinks";
import LiveStreamList from './liveStreams/LiveStreamList';
import MuiDialog from "@material-ui/core/Dialog";

interface FormProcessingStatus {
    sent: boolean;
    status?: Color;
    message?: string;
}

export type SaveableEvent = Omit<EventOutDto , 'regionIds' | 'status' > & {
    id?: number, // Optional when creating
    regionId: number, // API supports regionIds, UX supports regionId
};

export interface EventFormParams {
    event: SaveableEvent,
    validator: any; // Yup Validator, but i don't know what to use
    onSubmit: (values: SaveableEvent, formikHelpers: FormikHelpers<SaveableEvent>) => Promise<EventPatchDto> | void;
    shuttleLinks?: EventsShuttlelinksDto[]
}

export const EventForm = (props: EventFormParams) => {
    const { config } = useSelector((state: AppStateType) => state.configReducer);
    const history = useHistory();
    // Declare the APIs we are communicating with
    const listenApiService = new ListenApiService({ basePath: config.resources.lapi.endpoint });
    const eventManagerService = new EventManagerService({ basePath: config.resources.livestream.endpoint });

    // Status holds the form's state, mostly taken from theme
    const [status, setStatus] = useState<FormProcessingStatus>({
        sent: false
    });

    // Remote datasets
    const [applications, setApplications] = useState<App[]>();
    const [regions, setRegions] = useState<Region[]>();
    const [stations, setStations] = useState<Station[]>();

    // Image states
    const [cropImage, setCropImage] = useState<CropImageInterface | null>(null);
    const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);

    let [selectableApplications, setSelectableApplications] = useState<App[]>();
    const [region, setRegion] = useState<Region>();

    // Deleting an event
    const confirmEventDeletion = () => {
        if(!window.confirm(`Are you sure you want to delete ${props.event.title}?`)) {
            return;
        }

        eventManagerService.deleteEvent(props.event.id).then(
            () => {
                window.alert('Deleted!');
                history.replace(`/`);
            },
            () => window.alert(`Failed to delete ${props.event.title}`)
        )
    }

    const handleSubmit = (values: SaveableEvent, formikHelpers: FormikHelpers<SaveableEvent>) => {
        if (!region || !region.StationRegionTimezone) return;

        const response = props.onSubmit(values, formikHelpers);
        if (!response) return;

        // Update form's state with the latest information
        response
            .then((updatedEvent) => {
                setStatus({sent: true, status: 'success', message: 'Saved successfully'});
                return updatedEvent;
            })
            .catch((error) => {
                setStatus({
                    sent: false,
                    status: 'error',
                    message: 'Event failed to save: ' + (error.message ?? error.statusText)
                });

                // Handle ReadableStream promise
                error.json().then((json: any) => {
                    setStatus({
                        sent: false,
                        status: 'error',
                        message: 'Event failed to save: ' + (json.message)
                    });
                });
            })
            .finally(() => {
                form.setSubmitting(false);
            });
    }

    const form = useFormik({
        initialValues: props.event,
        validationSchema: props.validator,
        onSubmit: handleSubmit,
    });

    // "Construct"
    // @note eslint is wrong. "[]" is intentional, else we will end up in an endless loop
    useEffect(() => {
        fetchRegions();
        fetchApplications();

    }, []);

    useEffect(() => {
        filterSelectionsByRegion()
    }, [applications, regions]);

    // Update filters when content has changed
    useEffect(() => {
        const region = getRegionFromId(form.values.regionId);
        if (!region || !region.StationRegionTimezone) return;
        setRegion(region);

        // Loads timezone information into runtime, so the date pickers can use it
        moment.tz(region.StationRegionTimezone);

    }, [form.values.regionId]);

    const getRegionFromId = (regionId: number): Region | undefined => {
        return regions?.find((r) => r.StationRegionId === regionId);
    }

    useEffect(() => {
        if (!region || !region.StationRegionTimezone) return;

        filterSelectionsByRegion();
    }, [region])


    // Correct formatting of highlight colour in realtime
    useEffect(() => {
        const {highlightColour} = form.values;
        if (highlightColour && highlightColour.length === 6 && highlightColour[0] !== '#') {
            form.setFieldValue('highlightColour', form.values.highlightColour = '#' + highlightColour);
        }
    }, [form.values.highlightColour]);


    const fetchApplications = async (): Promise<void> => {
        if (!applications) {
            const response = await listenApiService.getApps({});
            setApplications(response.data ?? []);
        }
    };

    /**
     * Retrieve stations; updated selectable stations
     * @param regionId
     */
    const fetchStations = async (regionId: number): Promise<void> => {
        if (!regions || !region) return;

        const response = await listenApiService.getMinifiedStations({
            regionCode: region.StationRegionCode as any, // We can trust this
            premium: 1,
        });
        setStations(response.data ?? []);
    };

    const fetchRegions = async (): Promise<void> => {
        const response = await listenApiService.getRegions({});
        setRegions(response.data ?? []);

        // Populate the original region
        const originRegion = response.data.find((r) => r.StationRegionId === form.values.regionId);
        if (originRegion && originRegion.StationRegionTimezone) {

            // Loads timezone information into runtime, so the date pickers can use it
            moment.tz(originRegion.StationRegionTimezone);

            await setRegion(originRegion);
        }


    };

    /**
     * Retrieve regions; updated selectable applications
     */
    const filterSelectionsByRegion = () => {
        if (!applications || !region || !region.StationRegionId) return;

        // Stations are updated from a fresh request
        fetchStations(region.StationRegionId);

        // Applications are updated from initial request
        setSelectableApplications(
            applications.filter((app) => app.AppRegionId === region.StationRegionId)
        );
    }


    const handleFileInputChange = (e: any) => {
        const file = e.target.files[0];
        const image = images.find((image: CropImageInterface) => image.key === e.target.id);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            if (image) setCropImage({...image, previewSource: reader.result as string});
        };
    };

    const uploadImage = async (base64EncodedImage: string) => {
        try {
            setIsUploadingImage(true);
            const response = await eventManagerService.uploadImage({base64EncodedImage});
            if (cropImage?.key) await form.setFieldValue(cropImage.key, response.url);
            setCropImage(null); // Reset crop image object
            setStatus({sent: true, status: 'success', message: 'Image uploaded successfully'});
        } catch (error) {
            setStatus({
                sent: false,
                status: 'error',
                message: error.message ?? error.statusText ?? 'Something went wrong'
            });
        } finally {
            setIsUploadingImage(false);
        }
    };

    const images: CropImageInterface[] = [
        {
            key: "livePopupImageHero",
            name: "Live Video Promotional Popup Hero Image",
            aspect: 16 / 9,
            description: "Aspect Radio 16:9 in .jpg or .png format",
            placeholder: 'https://dummyimage.com/1280x720/cccccc/ffffff',
            value: form.values.livePopupImageHero,
            section: 'livePopupImages'
        },
        {
            key: "imageHeroSmall",
            name: "Small Hero Image",
            aspect: 16 / 9,
            description: "Aspect Radio 16:9 in .jpg or .png format",
            placeholder: 'https://dummyimage.com/720x405/cccccc/ffffff',
            value: form.values.imageHeroSmall,
            section: 'footerImages'
        },
        {
            key: "imageHeroLarge",
            name: "Large Hero Image",
            aspect: 16 / 9,
            description: "Aspect Radio 16:9 in .jpg or .png format",
            placeholder: 'https://dummyimage.com/1280x720/cccccc/ffffff',
            value: form.values.imageHeroLarge,
            section: 'footerImages'
        }
    ];

    const getImages = (section: string): CropImageInterface[] => {
        return images.filter(image => image.section === section);
    }

    const formatSlug = (slug: string): string => {
        slug = slugify(slug).toLowerCase();
        slugValidation(slug);
        return slug;
    }

    const slugValidation = async (slug: string) => {
        setStatus({ sent: false });
        if (!slug) return;

        let message;
        try {
            const response = await eventManagerService.validateSlug(slug, props.event.id);
            if (response.status) {
                return;
            }
            message = response.message;
            form.setFieldError('slug', message);
            form.dirty = true;
        } catch (e) {
            message = e.message;
        }

        setStatus({
            sent: false,
            status: 'error',
            message: message
        });
    }

    return !props.event ? null : <div id="event"><Grid container spacing={10}>
        <Grid item>
            <Typography variant='h2' gutterBottom>
                {props.event.id ? props.event.title : "Create Event"}
            </Typography>
            <Breadcrumbs aria-label='Breadcrumb' mt={2}>
                <Link component={NavLink} exact to='/'>
                    Events
                </Link>
                <Typography>
                    {props.event.id ? props.event.title : "Create Event"}
                </Typography>
            </Breadcrumbs>
        </Grid>
    </Grid>

        <Divider my={6}/>

        {status && status.message && (
            <Alert severity={status.status} my={3}>
                {status.message}
            </Alert>
        )}

        {form.isSubmitting ? (
            <Box display='flex' justifyContent='center' my={6}>
                <CircularProgress/>
            </Box>
        ) : (
            <form onSubmit={form.handleSubmit}>
                <Card>
                    <CardContent>
                        <Typography variant='h6' gutterBottom>
                            Event Information
                        </Typography>

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name='title'
                                    label='Event title'
                                    value={form.values.title}
                                    error={Boolean(
                                        form.touched.title && form.errors.title
                                    )}
                                    fullWidth
                                    helperText={form.touched.title && form.errors.title}
                                    onBlur={(e) => {
                                        // Suggest slug through title
                                        if(form.values.slug.length > 0) return;
                                        form.setFieldValue('slug', formatSlug(e.target.value), true);
                                        form.handleChange(e);
                                    }}
                                    onChange={form.handleChange}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name='slug'
                                    label='Slug'
                                    value={form.values.slug}
                                    error={Boolean(form.errors.slug)}
                                    fullWidth
                                    helperText={form.errors.slug}
                                    onBlur={(e) => {
                                        form.setFieldValue('slug', formatSlug(form.values.slug), true);
                                        form.handleBlur(e);
                                    }}
                                    onChange={form.handleChange}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel id="EventType">Event type</InputLabel>
                                    <Select
                                        name='eventType'
                                        label='Event type'
                                        labelId='EventType'
                                        placeholder='Select Event Type'
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                        value={form.values.eventType}
                                        error={Boolean(
                                            form.touched.eventType && form.errors.eventType
                                        )}
                                    >
                                        {EventTypes.map((type) => (
                                            <MenuItem value={type.type} key={type.type}>
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{position: 'relative'}}>
                                <TextField
                                    name='highlightColour'
                                    label='Highlight Colour'
                                    type='text'
                                    style={{outlineColor: form.values.highlightColour}}
                                    // style={{ height: '50px', width: '50px' }}
                                    value={form.values.highlightColour}
                                    error={Boolean(
                                        form.touched.highlightColour &&
                                        form.errors.highlightColour
                                    )}
                                    fullWidth
                                    helperText={
                                        form.touched.highlightColour &&
                                        form.errors.highlightColour
                                    }
                                    onBlur={form.handleBlur}
                                    onChange={form.handleChange}
                                    variant='outlined'
                                />
                                <div style={{
                                    height: '30px',
                                    width: '30px',
                                    position: "absolute",
                                    right: '25px',
                                    top: '24px',
                                    backgroundColor: form.values.highlightColour
                                }}>&nbsp;</div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                  name='description'
                                  label='Description'
                                  value={form.values.description}
                                  error={Boolean(
                                    form.touched.description && form.errors.description
                                  )}
                                  fullWidth
                                  multiline={true}
                                  rows={10}
                                  helperText={form.touched.description && form.errors.description}
                                  onBlur={form.handleBlur}
                                  onChange={form.handleChange}
                                  variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            value={form.values.isLoginRequired}
                                            checked={form.values.isLoginRequired}
                                            name="isLoginRequired"
                                        />
                                    }
                                    label="Login is required"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            value={form.values.isFeaturedEvent}
                                            checked={form.values.isFeaturedEvent}
                                            name="isFeaturedEvent"
                                        />
                                    }
                                    label="Event is featured"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            value={form.values.isPremiumOnly}
                                            checked={form.values.isPremiumOnly}
                                            name="isPremiumOnly"
                                        />
                                    }
                                    label="Premium users only"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                {form.values.eventType === EventCreateDto.EventTypeEnum.Livestream && (<>
                    <Divider my={6}/>

                    <Card>
                        <CardContent>
                            <Typography variant='h6' gutterBottom>
                                Livestream promotional popup
                            </Typography>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                        value={form.values.isLivePopupEnabled}
                                        checked={form.values.isLivePopupEnabled}
                                        name="isLivePopupEnabled"
                                    />
                                }
                                label="Enabled"
                            />
                            {!form.values.isLivePopupEnabled ? null :
                                <div id="live-popup-details">
                                    <Grid container spacing={6}>
                                        <Grid item xs={3}>
                                            <TextField
                                                name='livePopupTitle'
                                                label='Popup Title'
                                                value={form.values.livePopupTitle}
                                                error={Boolean(
                                                    form.touched.livePopupTitle && form.errors.livePopupTitle
                                                )}
                                                fullWidth
                                                helperText={form.touched.livePopupTitle && form.errors.livePopupTitle}
                                                onBlur={form.handleBlur}
                                                variant='outlined'
                                                onChange={form.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField
                                                name='livePopupDescription'
                                                label='Description'
                                                value={form.values.livePopupDescription}
                                                error={Boolean(
                                                    form.touched.livePopupDescription && form.errors.livePopupDescription
                                                )}
                                                fullWidth
                                                helperText={form.touched.livePopupDescription && form.errors.livePopupDescription}
                                                onBlur={form.handleBlur}
                                                variant='outlined'
                                                onChange={form.handleChange}

                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={6}>
                                        <Grid item xs={3}>
                                            <TextField
                                                name='livePopupButtonText'
                                                label='Button Text'
                                                value={form.values.livePopupButtonText}
                                                error={Boolean(
                                                    form.touched.livePopupButtonText && form.errors.livePopupButtonText
                                                )}
                                                fullWidth
                                                helperText={form.touched.livePopupButtonText && form.errors.livePopupButtonText}
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField
                                                name='livePopupButtonUrl'
                                                label='Button URL'
                                                value={form.values.livePopupButtonUrl}
                                                error={Boolean(
                                                    form.touched.livePopupButtonUrl && form.errors.livePopupButtonUrl
                                                )}
                                                fullWidth
                                                helperText={form.touched.livePopupButtonUrl && form.errors.livePopupButtonUrl}
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                variant='outlined'
                                            />
                                        </Grid>
                                    </Grid>

                                    <Divider my={6}/>

                                    <Grid container spacing={6}>
                                        { getImages('livePopupImages').map((image) => <ImageUpload form={form} image={image} handleChange={handleFileInputChange} />) }
                                    </Grid>
                                </div>
                            }
                        </CardContent>
                    </Card>
                </>)}

                <Divider my={6}/>

                <Card>
                    <CardContent>
                        <Typography variant='h6' gutterBottom>
                            Associations
                        </Typography>

                        {stations ? null :
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <Typography variant="body2">Choose a region before selections Apps and Stations</Typography>
                                </Grid>
                            </Grid>
                        }

                        <Grid container spacing={6}>
                            <Grid item xs={4}>
                                {!regions ? null :
                                    <FormControl variant="outlined" fullWidth={true}>
                                        <InputLabel filled shrink id="Regions">Regions</InputLabel>
                                        <Select
                                            name='regionId'
                                            label='Regions'
                                            labelId='Regions'
                                            placeholder="Select region(s)..."
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            multiple={false}
                                            value={form.values.regionId}
                                            error={Boolean(
                                                form.touched.regionId && form.errors.regionId
                                            )}
                                        >
                                            {regions.map((region) => (
                                                <MenuItem value={region.StationRegionId} key={region.StationRegionId}>
                                                    {!region.StationRegionCode ? null :
                                                        <ListItemIcon>
                                                            <img
                                                                src={`/static/img/flags/${region.StationRegionCode.toLowerCase()}.png`}
                                                                alt={region.StationRegionCode}
                                                                style={{
                                                                    height: '1em',
                                                                }}/>
                                                        </ListItemIcon>
                                                    }
                                                    <span>{region.StationRegionName}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error>{form.touched.regionId && form.errors.regionId}</FormHelperText>
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                {!stations ? null :
                                    <Box>
                                        <FormControl variant="outlined" fullWidth={true}>
                                            <InputLabel filled shrink id="Stations">Stations</InputLabel>
                                            <Select
                                                name='stationIds'
                                                label='Stations'
                                                labelId='Stations'
                                                placeholder="Select stations(s)..."
                                                multiple={true}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.stationIds}
                                                displayEmpty={true}
                                                renderValue={() => {
                                                    return <span>Select Stations...</span>
                                                }}
                                                error={Boolean(
                                                    form.touched.stationIds && form.errors.stationIds
                                                )}
                                            >
                                                {stations.map((station) => (
                                                    <MenuItem value={station.stationId} key={station.stationId}>
                                                        <ListItemIcon>
                                                            <img src={station.stationListenBarLogo}
                                                                 alt={station.stationCode}
                                                                 style={{height: '2em',}}
                                                            />
                                                        </ListItemIcon>
                                                        <span>{station.stationName}</span>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText error>{form.touched.stationIds && form.errors.stationIds}</FormHelperText>
                                        </FormControl>

                                        <Box display="flex" flexDirection="column">
                                            {stations.filter(
                                                (s) => form.values.stationIds.indexOf(s.stationId) >= 0)
                                                .map((station) => (
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            alignItems="center"
                                                            mt={2}
                                                            key={station.stationId}>
                                                            <img src={station.stationListenBarLogo}
                                                                 alt={station.stationCode}
                                                                 style={{
                                                                     width: '3em',
                                                                     marginRight: '1em'
                                                                 }}/>
                                                            <span>{station.stationName}</span>
                                                            <IconButton
                                                                title="Remove from stations"
                                                                onClick={() => {
                                                                    form.values.stationIds.splice(form.values.stationIds.indexOf(station.stationId), 1);
                                                                    form.setFieldValue('stationIds', form.values.stationIds);
                                                                }}
                                                            ><DeleteIcon/></IconButton>
                                                        </Box>
                                                    )
                                                )}
                                        </Box>
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                {!selectableApplications?.length ? null :
                                    <Box>
                                        <FormControl variant="outlined" fullWidth={true}>
                                            <InputLabel filled shrink id="Apps">Apps</InputLabel>
                                            <Select
                                                name='appIds'
                                                label='Apps'
                                                labelId='Apps'
                                                placeholder="Select app(s)..."
                                                multiple={true}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.appIds}
                                                displayEmpty={true}
                                                renderValue={() => {
                                                    return <span>Select Apps...</span>
                                                }}
                                                fullWidth={true}
                                                error={Boolean(
                                                    form.touched.appIds && form.errors.appIds
                                                )}
                                                variant="outlined"
                                            >
                                                {selectableApplications.map((app) => (
                                                    <MenuItem value={app.AppId} key={app.AppId}>
                                                        <ListItemIcon>
                                                            <img
                                                                src={app.AppIconImageUrl || app.AppIconImageUrl}
                                                                alt={app.AppCode}
                                                                style={{width: '2em',}}
                                                            />
                                                        </ListItemIcon>
                                                        <span>{app.AppName}</span>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText error>{form.touched.appIds && form.errors.appIds}</FormHelperText>
                                        </FormControl>
                                        <Box display="flex" flexDirection="column">
                                            {selectableApplications
                                                .filter((app) => app.AppId !== undefined)
                                                .filter((app) => form.values.appIds.indexOf(app.AppId ?? -1) >= 0)
                                                .map((app) => (
                                                        <Box display="flex" flexDirection="row" alignItems="center" mt={2}
                                                             key={app.AppId}>
                                                            <img
                                                                alt={app.AppCode}
                                                                src={app.AppIconImageUrl || app.AppIconImageUrl}
                                                                style={{width: '3em', marginRight: '1em'}}
                                                            />
                                                            <span>{app.AppName}</span>
                                                            <IconButton
                                                                title="Remove from apps"
                                                                onClick={() => {
                                                                    if (app.AppId) {
                                                                        form.values.appIds.splice(form.values.appIds.indexOf(app.AppId), 1);
                                                                        form.setFieldValue('appIds', form.values.appIds);
                                                                    }
                                                                }}
                                                            ><DeleteIcon/>
                                                            </IconButton>
                                                        </Box>
                                                    )
                                                )}
                                        </Box>
                                    </Box>
                                }

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Divider my={6} />

                <Card>
                    <CardContent>
                          <Grid container spacing={6}>
                              <Grid item xs={12} sm={6}>
                                  <Typography variant='h6' gutterBottom>
                                      Marketing period
                                  </Typography>

                                  <Grid container spacing={6}>
                                      <Grid item md={6}>
                                          <DatePickerWithTimezone
                                            name='startAt'
                                            timezone={region?.StationRegionTimezone}
                                            selected={moment(form.values.startAt).toDate()}
                                            onBlur={form.handleBlur}
                                            onChange={(option: Date) => {
                                                form.setFieldValue('startAt', option)
                                            }}
                                            textFieldProps={{
                                                label: 'Starts at',
                                                error: Boolean(form.touched.startAt && form.errors.startAt),
                                                helperText: form.touched.startAt && form.errors.startAt
                                            }}

                                          />
                                      </Grid>
                                      <Grid item md={6}>
                                          <DatePickerWithTimezone
                                            name='endAt'
                                            timezone={region?.StationRegionTimezone}
                                            selected={moment(form.values.endAt).toDate()}
                                            onBlur={form.handleBlur}
                                            onChange={(option: Date) => {
                                                form.setFieldValue('endAt', option)
                                            }}
                                            textFieldProps={{
                                                label: 'Ends at',
                                                error: Boolean(form.touched.endAt && form.errors.endAt),
                                                helperText: form.touched.endAt && form.errors.endAt
                                            }}
                                          />
                                      </Grid>
                                  </Grid>
                              </Grid>
                              {form.values.eventType === EventCreateDto.EventTypeEnum.Livestream && (<Grid item xs={12} sm={6}>
                                  <Grid container>
                                      <Grid item xs={6}>
                                          <Typography variant='h6' gutterBottom>Livestream broadcast period</Typography>
                                      </Grid>

                                      <Grid item xs={6}>
                                          {!region || !region.StationRegionTimezone ? null :
                                            <Box color="text.secondary" textAlign="right">
                                                Time zone: {region.StationRegionTimezone}
                                            </Box>
                                          }
                                      </Grid>

                                  </Grid>
                                  <Grid container spacing={6}>
                                      <Grid item md={6}>
                                          <DatePickerWithTimezone
                                            name='liveStartAt'
                                            timezone={region?.StationRegionTimezone}
                                            selected={form.values.liveStartAt ? moment(form.values.liveStartAt).toDate() : null}
                                            onBlur={form.handleBlur}
                                            onChange={(option: Date) => {
                                                form.setFieldValue('liveStartAt', option)
                                            }}
                                            textFieldProps={{
                                                label: 'Starts at',
                                                error: Boolean(form.touched.liveStartAt && form.errors.liveStartAt),
                                                helperText: form.touched.liveStartAt && form.errors.liveStartAt
                                            }}
                                          />
                                      </Grid>
                                      <Grid item md={6}>
                                          <DatePickerWithTimezone
                                            name='liveEndAt'
                                            timezone={region?.StationRegionTimezone}
                                            selected={form.values.liveEndAt ? moment(form.values.liveEndAt).toDate() : null}
                                            onBlur={form.handleBlur}
                                            onChange={(option: Date) => {
                                                form.setFieldValue('liveEndAt', option)
                                            }}
                                            textFieldProps={{
                                                label: 'Ends at',
                                                error: Boolean(form.touched.liveEndAt && form.errors.liveEndAt),
                                                helperText: form.touched.liveEndAt && form.errors.liveEndAt
                                            }}
                                          />
                                      </Grid>
                                  </Grid>
                              </Grid>)}
                          </Grid>
                    </CardContent>
                </Card>

                <Divider my={6}/>

                <Card>
                    <CardContent>
                        <Grid container>
                            { getImages('footerImages').map((image) => <ImageUpload image={image} handleChange={handleFileInputChange} form={form} />) }
                            <CropDialog image={cropImage} setImage={setCropImage} handleUpload={uploadImage} isUploadingImage={isUploadingImage} />
                        </Grid>
                    </CardContent>
                </Card>

                {!!props.event.id && (<React.Fragment>
                    <Divider my={6} />
                    <Card>
                        <CardContent>
                            <Typography variant='h6' gutterBottom>
                                Upload on-demand videos
                            </Typography>

                            <VideoList videos={form.values.videos} eventId={form.values.id} />

                        </CardContent>
                    </Card>
                </React.Fragment>)}

                {!!props.event.id && form.values.eventType === EventCreateDto.EventTypeEnum.Livestream && (<React.Fragment>
                    <Divider my={6} />
                    <Card>
                        <CardContent>
                            <LiveStreamList liveStreams={form.values.eventsLivestreams} eventId={form.values.id} />
                        </CardContent>
                    </Card>
                </React.Fragment>)}

                <Divider my={6} />

                <ShuttleLinks regions={regions ?? []} stations={stations ?? []} applications={applications ?? []} shuttleLinks={props.shuttleLinks ?? []} />

                <CenteredContent>
                    {props.event.id &&
                        <Button
                            type='button'
                            variant='contained'
                            color='primary'
                            style={{backgroundColor: 'red', opacity: 0.5}}
                            mt={3}
                            mr={10}
                            onClick={confirmEventDeletion}
                            startIcon={<DeleteIcon/>}>
                            Delete event
                        </Button>
                    }
                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        mt={3}
                        startIcon={<SaveIcon/>}
                    >
                        Save
                    </Button>
                </CenteredContent>
            </form>
        )}
    </div>


}
