import * as types from "../../constants";

export type UserType = {
    id?: string;
    email?: string;
    name?: string;
    isAdmin?: boolean;
};

export type AuthType = {
    user?: UserType | undefined;
    isAuthing: boolean;
};

export default function reducer(
    state = {},
    actions: UserType & { type: string }
): AuthType {
    switch (actions.type) {
        case types.AUTH_SIGN_IN_SUCCESS:
            return {
                ...state,
                user: {
                    id: actions.id,
                    email: actions.email,
                    name: actions.name,
                    isAdmin: actions.isAdmin,
                },
                isAuthing: false,
            };

        case types.AUTH_SIGN_OUT:
            return {
                ...state,
                user: undefined,
                isAuthing: false,
            };

        case types.AUTH_SIGN_IN_REQUEST:
        default:
            return {
                ...state,
                user: undefined,
                isAuthing: true,
            };
    }
}
