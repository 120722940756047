import { EventManagerService } from "./eventManagerService";
import { UserDto } from "../apis/_generated/livestream";
import { ConfigInterface } from "../redux/actions/configActions";

export function getAuthedUser(config: ConfigInterface): Promise<UserDto> {
  const eventManagerService = new EventManagerService({ basePath: config.resources.livestream.endpoint });

    return new Promise((resolve, reject) => {
      return eventManagerService.getUser()
            .then((user) => {
                resolve(user);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
