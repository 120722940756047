import styled from 'styled-components/macro';
import {
    Avatar,
    Box as MuiBox, Breadcrumbs as MuiBreadcrumbs, Button as MuiButton,
    Card as MuiCard, CardContent as MuiCardContent, CircularProgress as MuiCircularProgress, Divider as MuiDivider,
    Grid as MuiGrid, IconButton as MuiIconButton, Paper as MuiPaper, Select as MuiSelect,
    TextField as MuiTextField,
    Typography as MuiTypography,
    FormControl as MuiFormControl,
    CardMedia as MuiCardMedia,
    Chip as MuiChip,
} from "@material-ui/core";
import { display, spacing, SpacingProps } from '@material-ui/system';
import { Alert as MuiAlert } from '@material-ui/lab';
import { CloudUpload as MuiCloudUpload } from '@material-ui/icons';
import variants from '../theme/variants';


// Commonly used elements, with global styled injected
// Taken from theme templates which were not exported/shared
export const Card = styled(MuiCard)(spacing);
export const Alert = styled(MuiAlert)(spacing);
// Why can we not add (spacing) and it just work on TextField?
export const TextField = styled(MuiTextField)<{ m?: number, mx?: number, my?: number }>(spacing);
export const Grid = styled(MuiGrid)(spacing);
export const Box = styled(MuiBox)(spacing);
export const Typography = styled(MuiTypography)(display);
export const Divider = styled(MuiDivider)(spacing);
export const CloudUpload = styled(MuiCloudUpload)(spacing);
export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
export const BigImage = styled(Avatar)`
    margin: 20px auto;
    width: 50%;
    height: 50%;
    border-radius: 5px;
`;
export const CenteredContent = styled.div`
    text-align: center;
`;
export const CardContent = styled(MuiCardContent)`
    position: relative;

    &:last-child {
        padding-bottom: ${(props) => props.theme.spacing(4)}px;
    }
`;
export const CircularProgress = styled(MuiCircularProgress)(spacing);
export const Select = styled(MuiSelect)<{
    mt?: number;
}>(spacing);

interface ButtonPropstype extends SpacingProps {
    component?: string;
}
export const Button = styled(MuiButton)<ButtonPropstype>(spacing);
export const Flag = styled.img`
    height: 12px;
    margin: 0px 3px;
    border: 1px solid #333;
`;
export const Paper = styled(MuiPaper)(spacing);
export const Spacer = styled.div`
    flex: 1 1 100%;
`;
export const ToolbarTitle = styled.div`
    min-width: 150px;
`;

export const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

export const AuthLoaderBlock = styled.div`
    background-color: ${variants[0].palette.background.default};
    position: absolute;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999999;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const FormControlSpacing = styled(MuiFormControl)(spacing);
export const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;
export const Chip = styled(MuiChip)<{ rgbcolor?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;
export const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;
