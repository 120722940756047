import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography
} from '@material-ui/core';
import {
    Add as AddIcon,
    RemoveRedEye as RemoveRedEyeIcon
} from '@material-ui/icons';
import { DataResponsePagination, EventOutDto } from '../../apis/_generated/livestream';
import { DateTimeComponent } from '../../components/Dates';
import {EventManagerService} from '../../services/eventManagerService';
import { ListenApiService } from '../../services/listenApiService';
import { Region } from '../../apis/_generated/listenapi';
import { Flag, Divider, Paper, ToolbarTitle, Chip } from '../../components/Common';
import { useSelector } from 'react-redux';
import { AppStateType } from '../../redux/reducers';
import { green, grey } from '@material-ui/core/colors';
import { getEventStatusLabel } from '../../constants/eventStatuses';

// Event fields that can be sorted (sort method incompatible with nulls)
function descendingComparator(
    a: any | Event,
    b: any | Event,
    orderBy: string
) {
    const aValue: string | number | null = a[orderBy] ?? null;
    const bValue: string | number | null = b[orderBy] ?? null;

    if (aValue === null) return -1;
    if (bValue === null) return 1;

    if (bValue < aValue) return -1;
    if (bValue > aValue) return 1;

    return 0;
}


function getComparator(order: 'desc' | 'asc', orderBy: string) {
    return order === 'desc'
        ? (a: EventOutDto, b: EventOutDto) => descendingComparator(a, b, orderBy)
        : (a: EventOutDto, b: EventOutDto) => -descendingComparator(a, b, orderBy);
}

/**
 * Applies sort to records
 * @param array
 * @param comparator
 */
function stableSort(
    array: EventOutDto[],
    comparator: (a: EventOutDto, b: EventOutDto) => number
) {
    const stabilizedThis = array.map((el: EventOutDto, index: number) => ({
        el,
        index
    }));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0) return order;
        return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
}

type HeadCell = {
    id: string;
    alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
    label: string;
    disablePadding?: boolean;
};
const headCells: HeadCell[] = [
    { id: 'id', alignment: 'right', label: '#' },
    { id: 'title', alignment: 'left', label: 'Event' },
    { id: 'startAt', alignment: 'left', label: 'Marketing period' },
    { id: 'liveStartAt', alignment: 'left', label: 'Broadcast period' },
    { id: "status", alignment: "left", label: "Status" },
    { id: "actions", alignment: "right", label: "Actions" },
];

type EnhancedTableHeadPropsType = {
    numSelected: number;
    order: 'desc' | 'asc';
    orderBy: string;
    rowCount: number;
    onSelectAllClick: (e: any) => void;
    onRequestSort: (e: any, property: string) => void;
};

/**
 * Build the Event table's head
 * @param props
 * @constructor
 */
const EventTableHead: React.FC<EnhancedTableHeadPropsType> = (props) => {
    const {
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = (property: string) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell: HeadCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.alignment}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const EventsToolbar = (props: {}) => {
    return (
        <Toolbar>
            <ToolbarTitle>
                <Typography variant='h6' id='tableTitle'>
                    Events
                </Typography>
            </ToolbarTitle>
        </Toolbar>
    );
};

function EventsTable(props: {
    onPageChange: (page: { _p: number, _pp: number }) => void,
    meta: DataResponsePagination,
    events: EventOutDto[]
}) {
    const history = useHistory();

    const { config } = useSelector((state: AppStateType) => state.configReducer);
    // Declare the APIs we are communicating with
    const [listenApiService] = useState<ListenApiService>(new ListenApiService({ basePath: config.resources.lapi.endpoint }));
    // Remote data
    const [regions, setRegions] = useState<Region[]>([]);

    // Aggregation
    const [order, setOrder] = useState<'desc' | 'asc'>('asc');
    const [orderBy, setOrderBy] = useState('order');

    // Pagination
    const [page, setPage] = useState(0); // _p
    const [rowsPerPage, setRowsPerPage] = useState(25); // _pp

    const events = props.events;

    // Handle pagination by propogating it up to parent, who will eventuall re-inject events
    useEffect(() => {
        props.onPageChange({ _p: page , _pp: rowsPerPage });
    }, [page, rowsPerPage]);

    useEffect(() => {
         fetchRegions();
    }, []);

    const fetchRegions = async (): Promise<void> => {
        const response = await listenApiService.getRegions({});
        setRegions(response.data ?? []);
    };

    const getRegionFromId = (regionId: number): Region | undefined => {
        return regions.find((r) => r.StationRegionId === regionId);
    }

    const handleRequestSort = (event: any, property: string): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const gotoEvent = (event: EventOutDto) => {
        history.push(`/events/${event.id}`);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    return (
        <div>
            <Paper>
                <EventsToolbar />
                <TableContainer>
                    <Table
                        aria-labelledby='tableTitle'
                        size={'medium'}
                        aria-label='enhanced table'
                    >
                        <EventTableHead
                            numSelected={0}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={() => {
                                return;
                            }}
                            onRequestSort={handleRequestSort}
                            rowCount={events.length}
                        />
                        <TableBody>
                            {stableSort(events, getComparator(order, orderBy))
                                .map(
                                    (livestreamEvent: EventOutDto, index: number) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={livestreamEvent.id}
                                            >
                                                <TableCell align='right'>
                                                    #{livestreamEvent.id}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {livestreamEvent.title} {livestreamEvent.regionIds.map((regionId) => {
                                                        const region = getRegionFromId(regionId);
                                                        if(!region?.StationRegionCode) return null;
                                                        const flag = `/static/img/flags/${region.StationRegionCode.toLowerCase()}.png`;
                                                        return <Flag src={flag} alt={region.StationRegionName} title={region.StationRegionName} />;
                                                  })}
                                                </TableCell>
                                                <TableCell align='left'>
                                                   <DateTimeComponent date={livestreamEvent.startAt} />
                                                    <small> &rarr; </small>
                                                    <DateTimeComponent date={livestreamEvent.endAt} />
                                                </TableCell>
                                                <TableCell align='left'>
                                                    <DateTimeComponent date={livestreamEvent.liveStartAt} />
                                                    <small> &rarr; </small>
                                                    <DateTimeComponent date={livestreamEvent.liveEndAt} />
                                                </TableCell>

                                                <TableCell>
                                                    <Chip
                                                      label={livestreamEvent.status === EventOutDto.StatusEnum.Publish ? "Active" : "Inactive"}
                                                      rgbcolor={livestreamEvent.status === EventOutDto.StatusEnum.Publish ? green[500] : grey[500]}
                                                    />
                                                </TableCell>

                                                <TableCell
                                                    padding='none'
                                                    align='right'
                                                >
                                                    <Box mr={2}>
                                                        <IconButton aria-label='details' onClick={() => gotoEvent(livestreamEvent)}>
                                                            <RemoveRedEyeIcon />
                                                        </IconButton>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {!props.meta ? null :
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={props.meta.totalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }
            </Paper>
        </div>
    );
}

interface EventPageQuery {
    _p: number,
    _pp: number;
}


/**
 * Full events list page
 * @param props
 * @constructor
 */
const EventListPage = (props: EventPageQuery = {
    _p: 1,
    _pp: 25
}) => {
    const { config } = useSelector((state: AppStateType) => state.configReducer);
    const [eventManagerService] = useState<EventManagerService>(new EventManagerService({ basePath: config.resources.livestream.endpoint }));
    const [events, setEvents] = useState<EventOutDto[]>([]);
    const [pageMeta, setPageMeta] = useState<DataResponsePagination | undefined>(undefined);

    const history = useHistory();

    useEffect(() => {
        fetchEvents(props).then((response) => {
            setEvents(response.items);
            setPageMeta(response.meta);
        });
    }, []);

    /**
     * Retrieve events from the API
     * @param props
     */
    const fetchEvents = async (props: EventPageQuery) => {
        // Initial events requests, built from route
        return await eventManagerService.getEvents(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            props._p ? props._p + 1 : 1,
            props._pp ?? 25,
            undefined
        );

    };

    const handlePageChange = async(pagination: { _p: number, _pp: number }) => {
        fetchEvents({
            ...props,
            ...pagination
        }).then((response) => {
            setEvents(response.items);
            setPageMeta(response.meta);
        });
    }

    /**
     * Action: Navigate to event creation page
     */
    const startCreatingEvent = () => {
        history.push('/events/create');
    }

    return (
        <React.Fragment>
            <Helmet title='Events' />

            <Grid justify='space-between' container spacing={10}>
                <Grid item>
                    <Typography variant='h3' gutterBottom display='inline'>
                        Events
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant='contained' color='primary' onClick={startCreatingEvent} >
                            <AddIcon />
                            Create Event
                        </Button>
                    </div>
                </Grid>
            </Grid>

            <Divider my={6} />

            {!events || !pageMeta ? 'No events to display' : (
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <EventsTable
                            events={events}
                            meta={pageMeta}
                            onPageChange={handlePageChange}
                        />
                    </Grid>
                </Grid>
            )}

        </React.Fragment>
    );

};


export default EventListPage;
