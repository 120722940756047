import React, { ChangeEvent, useEffect, useState } from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import { CreateVideoDto, VideoDto } from '../../../apis/_generated/livestream';
import { VideoLoginRequired, VideoLoginRequiredEnum } from '../../../constants/videoLoginRequired';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    FormControl,
    FormControlLabel,
    FormLabel,
    LinearProgress,
    makeStyles,
    Radio,
    RadioGroup
} from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { EventManagerService } from '../../../services/eventManagerService';
import { useFormik } from 'formik';
import { VideoFormValidator } from './Validator';
import { Alert, Box, Grid, IconButton, TextField, Typography } from '../../../components/Common';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { VideoUploader } from '@api.video/video-uploader'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { AppStateType } from '../../../redux/reducers';

export type SaveableVideo = Partial<Omit<VideoDto, 'source'>> & {
    // This will be moved into the metadata array before sending to /api
    loginRequired?: VideoLoginRequiredEnum,
    thumbnail?: string,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleBar: {
            padding: theme.spacing(0),
        },
        title: {
            padding: theme.spacing(4, 4, 4),
        },
        container: {
            padding: theme.spacing(0, 5, 5),
        },
        thumbnailContainer: {
            textAlign: 'center'
        },
    }),
);

export interface VideoDialogProps {
    open: boolean,
    handleClose: () => void,
    videosList: VideoDto[] | undefined,
    updateVideoList: (videoDto: VideoDto) => void,
    setVideos: React.Dispatch<React.SetStateAction<VideoDto[] | undefined>>,
    video: SaveableVideo
}

function percentageOf(max: number, amount: number): number {
    if (max === 0) return 0;
    return (amount * 100) / max;
}

const VideoDialog = (props: VideoDialogProps) => {
    const { config } = useSelector((state: AppStateType) => state.configReducer);
    const eventManagerService = new EventManagerService({ basePath: config.resources.livestream.endpoint });
    const classes = useStyles();

    const [videoSaving, setVideoSaving] = useState(false);
    const [videoSavingError, setVideoSavingError] = useState('');

    const [thumbnail, setThumbnail] = useState(props.video.thumbnail);
    const [thumbnailUploadInProgress, setThumbnailUploadInProgress] = useState(false);
    const [thumbnailUploadFinished, setThumbnailUploadFinished] = useState(false);
    const [thumbnailUploadError, setThumbnailUploadError] = useState('');

    const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);
    const [videoUploadFinished, setVideoUploadFinished] = useState(false);
    const [videoUploadError, setVideoUploadError] = useState('');

    const thumbnailReset = () => {
        setThumbnailUploadError('');
        setThumbnailUploadFinished(false);
        setThumbnailUploadInProgress(false);
    }

    // Every time Dialog opens reset video upload values
    useEffect(() => {
        setVideoSaving(false);
        setVideoSavingError('');

        setVideoUploadPercentage(0);
        setVideoUploadFinished(false);
        setVideoUploadError('');
    }, [props.open])
    useEffect(() => {
        setThumbnail(props.video.thumbnail);
        thumbnailReset();
    }, [props.video.thumbnail])

    const handleSubmit = (values: SaveableVideo) => {
        setVideoSaving(true);

        if (values.metadata) {
            // Default loginRequired to no
            let loginRequired = VideoLoginRequiredEnum['no'];
            // -1 if not found else the index of the first element that matches
            const loginRequiredIndex = values.metadata.findIndex((o) => o.key === 'loginRequired');
            if (values.loginRequired !== undefined) {
                loginRequired = VideoLoginRequiredEnum[values.loginRequired];
            }
            if (-1 === loginRequiredIndex) {
                values.metadata.push({key: 'loginRequired', value: loginRequired})
            } else {
                values.metadata[loginRequiredIndex] = {key: 'loginRequired', value: loginRequired};
            }
        }

        const response = values.videoId
            ? eventManagerService.updateVideo(values, values.videoId as string)
            : eventManagerService.createVideo(values as CreateVideoDto);

        // Update form's state with the latest information
        response
            .then(async (videoDto) => {
                const video = await videoDto;
                props.updateVideoList(video);
            })
            .catch((error) => {
                setVideoSavingError('Video failed to save: ' + (error.message ?? error.statusText));
            })
            .finally(() => {
                props.handleClose();
            });
    }

    const handleThumbnailUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        thumbnailReset();
        if (!props.video.videoId) {
            setThumbnailUploadError('No videoId associated with thumbnail');
            return;
        }
        if (!event.currentTarget.files?.[0]) {
            setThumbnailUploadError('No thumbnail to upload');
            return;
        }

        setThumbnailUploadInProgress(true);

        const formData = new FormData()
        formData.append('file', event.currentTarget.files[0])
        /**
         * The client generate via swagger-codegen3 cannot cope with uploading files.
         * It uploads as the wrong content-type, `application/x-www-form-urlencoded`
         * when it need to be `multipart/form-data`.
          */
        await fetch(
            `${config.resources.livestream.endpoint}/api/v1/videos/thumbnail/${props.video.videoId}`,
            {
                method: 'POST',
                body: formData
            })
            .then(async (response) => {
                setThumbnailUploadFinished(true);
                const responseJson = await response.json();
                if (response.status >= 200 && response.status < 300) {
                    setThumbnail(URL.createObjectURL(formData.get('file')));
                    if (props.videosList) {
                        props.updateVideoList(responseJson);
                    }
                } else {
                    setThumbnailUploadError(responseJson.message);
                }
            })
            .catch((error) => {
                setThumbnailUploadError(error.message);
                console.log(error.status, error.message)
            })
            .finally(() => {
                setThumbnailUploadInProgress(false);
            })
    }

    const handleVideoUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget?.files?.[0]) {
            setVideoUploadPercentage(1);
            const videoToUpload = event.currentTarget.files[0];
            await eventManagerService.getDelegatedToken(form.values.videoId as string)
                .then(async (uploadToken) => {
                    const uploader = new VideoUploader({
                        videoId: form.values.videoId,
                        file: videoToUpload,
                        uploadToken: uploadToken.token as string,
                        chunkSize: config.services.apiVideo.videoChunkSize, // Currently 100MB
                        retries: config.services.apiVideo.videoRetryAttempts,
                    });
                    uploader.onProgress((event) => {
                        setVideoUploadPercentage(percentageOf(event.chunksCount, event.currentChunk))
                    });
                    await uploader.upload()
                        .then((video) => {
                            setVideoUploadFinished(true);
                        })
                        .catch((error) => {
                            setVideoUploadError(error.message);
                            console.log(error.status, error.message)
                        })
                })
                .finally(() => {
                    setVideoUploadPercentage(0);
                })
        }
    };

    const form = useFormik({
        initialValues: props.video,
        validationSchema: VideoFormValidator,
        onSubmit: handleSubmit,
        enableReinitialize: true, // As we are reusing this modal for all the videos on the page we need this
    });

    return (
        <MuiDialog open={props.open} onClose={props.handleClose} maxWidth={"md"} fullScreen={false}>
            <MuiDialogTitle disableTypography className={classes.titleBar}>
                <Box display="flex" p={1}>
                    <Box p={1} flexGrow={1}>
                        <Typography variant="h3" className={classes.title}>{ form.values.videoId ? 'Edit Video' : 'New Video' }</Typography>
                    </Box>
                    <Box p={1} order={3}>
                        <IconButton aria-label="close" color="primary" onClick={props.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </MuiDialogTitle>
            <Grid container className={classes.container}>
                <Grid item xs={12} md={12}>
                    <form onSubmit={form.handleSubmit}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={4}>
                                    {!form.values.videoId ? null :
                                        <Grid item xs={12}>
                                            <TextField
                                                id='videoId'
                                                name='videoId'
                                                label='videoId'
                                                value={form.values.videoId}
                                                fullWidth
                                                disabled
                                                variant='outlined'
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <TextField
                                            id='title'
                                            name='title'
                                            label='Title'
                                            value={form.values.title}
                                            autoFocus
                                            error={Boolean(form.touched.title && form.errors.title)}
                                            helperText={form.touched.title && form.errors.title}
                                            onBlur={form.handleBlur}
                                            onChange={form.handleChange}
                                            fullWidth
                                            variant='outlined'
                                        />
                                    </Grid>
                                    {!form.values.videoId ? null :
                                        <Grid item xs={12}>

                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Grid container justify='center' spacing={4}>
                                                        <Grid id='thumbnail' item xs={12} sm={6}>
                                                            <Typography variant="h4" gutterBottom>Video Thumbnail (1:1,
                                                                500px min, jpeg)</Typography>
                                                            <Card variant="outlined">
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}
                                                                          className={classes.thumbnailContainer}>
                                                                        <img
                                                                            src={!thumbnail ? '/static/img/video/no-thumbnail.png' : thumbnail}
                                                                            alt={form.values.videoId}
                                                                            style={{
                                                                                height: 'auto',
                                                                                maxWidth: '100%',
                                                                                maxHeight: '200px'
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} padding={4}>
                                                                        <input
                                                                            accept="image/jpeg"
                                                                            id="thumbnail"
                                                                            name="thumbnail"
                                                                            type="file"
                                                                            onChange={handleThumbnailUpload}
                                                                        />
                                                                        {thumbnailUploadInProgress && (
                                                                            <Grid item xs={12} paddingTop={4}>
                                                                                <LinearProgress/>
                                                                            </Grid>
                                                                        )}
                                                                        {thumbnailUploadFinished && !thumbnailUploadError && (
                                                                            <Grid item xs={12} paddingTop={4}>
                                                                                <Alert severity="success">Thumbnail
                                                                                    successfully uploaded</Alert>
                                                                            </Grid>
                                                                        )}
                                                                        {thumbnailUploadError && (
                                                                            <Grid item xs={12} paddingTop={4}>
                                                                                <Alert
                                                                                    severity="error">{thumbnailUploadError}</Alert>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>
                                                        </Grid>
                                                        <Grid id='video' item xs={12} sm={6}>
                                                            <Typography variant="h4" gutterBottom>Upload
                                                                Video</Typography>
                                                            <Card variant="outlined">
                                                                <Grid container spacing={0}>
                                                                    {/*
                                                                A future improvement may be to call eventManagerService.getStatus
                                                                and use the status of the video (missing|uploading|uploaded) to figure out
                                                                if we display a file input or not
                                                                */}
                                                                    <Grid item xs={12}>
                                                                        {!form.values.assets?.mp4 ?
                                                                            <CardMedia
                                                                                image={'/static/img/video/no-video.png'}
                                                                                title={'No video'}
                                                                                style={{height: 0, paddingTop: '62.5%'}}
                                                                            />
                                                                            : <CardMedia
                                                                                component='video'
                                                                                src={form.values.assets.mp4}
                                                                                title={form.values.title}
                                                                                controls
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={12} paddingBottom={0}>
                                                                        <Alert severity="info">Video cannot be changed
                                                                            once uploaded!</Alert>
                                                                    </Grid>
                                                                    <Grid item xs={12} padding={4}>
                                                                        <input
                                                                            accept="video/*"
                                                                            type="file"
                                                                            id="video-upload"
                                                                            onChange={handleVideoUpload}
                                                                        />
                                                                        {videoUploadPercentage > 0 && (
                                                                            <Grid item xs={12} paddingTop={4}>
                                                                                <LinearProgress variant="determinate"
                                                                                                value={videoUploadPercentage}/>
                                                                            </Grid>
                                                                        )}
                                                                        {videoUploadFinished && (
                                                                            <Grid item xs={12} paddingTop={4}>
                                                                                <Alert severity="success">Video
                                                                                    successfully uploaded</Alert>
                                                                            </Grid>
                                                                        )}
                                                                        {videoUploadError && (
                                                                            <Grid item xs={12} paddingTop={4}>
                                                                                <Alert
                                                                                    severity="error">{videoUploadError}</Alert>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Login required?</FormLabel>
                                            <RadioGroup
                                                aria-label="Login required?"
                                                name="Login required"
                                                value={form.values.loginRequired}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                row
                                            >
                                                {VideoLoginRequired.map((item, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        onChange={() => form.values.loginRequired = item.type}
                                                        value={item.type} control={<Radio/>} label={item.label}/>
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Grid container spacing={4}>
                                    <Grid item sm={6}>
                                        <Button variant="contained" color="secondary" onClick={props.handleClose}>Cancel</Button>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Grid container justify='flex-end'>
                                            {videoSaving
                                                ? null
                                                : <Button type='submit' variant="contained" color="primary"
                                                          startIcon={<SaveIcon/>}>Save</Button>
                                            }
                                        </Grid>
                                    </Grid>
                                    {videoSaving && (
                                        <Grid item xs={12} paddingTop={4}>
                                            <LinearProgress/>
                                        </Grid>
                                    )}
                                    {videoSavingError && (
                                        <Grid item xs={12} paddingTop={4}>
                                            <Alert severity="error">{videoSavingError}</Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardActions>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </MuiDialog>
    );
};

export default VideoDialog;
