import { EventCreateDto, EventOutDto } from '../../apis/_generated/livestream';
import { EventCreateFormValidator } from './Validator';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EventForm, SaveableEvent } from './EventForm';
import { FormikHelpers } from 'formik/dist/types';
import { EventManagerService } from '../../services/eventManagerService';
import { useSelector } from 'react-redux';
import { AppStateType } from '../../redux/reducers';

/**
 * Full events list page
 * @constructor
 */
const EventCreatePage = () => {
    const { config } = useSelector((state: AppStateType) => state.configReducer);
    const [eventManagerService] = useState<EventManagerService>(new EventManagerService({ basePath: config.resources.livestream.endpoint }));
    const history = useHistory();

    // Create empty event
    const [event] = useState<SaveableEvent>({
        id: 0,
        title: '',
        startAt: new Date(),
        endAt: new Date(),
        liveStartAt: new Date(),
        liveEndAt: new Date(),
        appIds: [],
        description: '',
        eventType: EventCreateDto.EventTypeEnum.Livestream,
        regionId: 0,
        stationIds: [],
        highlightColour: '#',
        imageHeroLarge: '',
        imageHeroSmall: '',
        isFeaturedEvent: true,
        isLivePopupEnabled: false,
        isLoginRequired: true,
        isPremiumOnly: false,
        livePopupButtonText: '',
        livePopupButtonUrl: 'https://default.com',
        livePopupDescription: '',
        livePopupTitle: '',
        livePopupImageHero: '',
        slug: '',
        videos: undefined,
        createdAt: new Date(),
        updatedAt: new Date(),
        eventsShuttlelinks: [],
        eventsLivestreams: [],
    });

    const handleSubmit = async (eventValues: SaveableEvent, formikHelpers: FormikHelpers<SaveableEvent>): Promise<EventOutDto> => {

        // @ts-ignore Ideally we would convert to EventCreateDto
        return eventManagerService.createEvent({
            ...eventValues,
            regionIds: [ eventValues.regionId ],
        })
            .then((event) => {
                // Display success message before forwarding user to event's actual page
                setTimeout(() => history.push(`/events/${event.id}`), 2000);
                return event;
            });
    };


    return (
        <EventForm
            event={event}
            validator={EventCreateFormValidator}
            onSubmit={handleSubmit}
        />
    );

};


export default EventCreatePage;
