import * as Yup from 'yup';
import { LiveStreamCreateDto } from '../../../apis/_generated/livestream';

export const LiveStreamFormValidator = Yup.object().shape({
    name: Yup.string().required("Required"),
    orientation: Yup.mixed().oneOf(Object.values(LiveStreamCreateDto.OrientationEnum)),
    record: Yup.string().required("Required"),

    // @see AS-4792 Comment this out while API video don't support 3k+ users in private lives
    // _public: Yup.string().required("Required"),
});
