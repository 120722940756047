import {format} from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import React, {ComponentProps, Fragment} from 'react';
import moment from 'moment-timezone';
import {BaseTextFieldProps, TextField} from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';

export const DateTimeComponent = (props: {
    date: Date | string | null,
    dateFormat?: string
}) => {
    const d = typeof props.date === 'string' ? new Date(props.date) : props.date;

    return <span>
        {d !== null ? format(d, props.dateFormat ?? 'yyyy-MM-dd HH:mm') : 'N/A'}
    </span>
}

/**
 * Date picker component that displays time based on a specific timezone (rather than user's local timezone)
 * whilst still storing/saving the date in the correct UTC format
 *
 * @param selected
 * @param onChange
 * @param timezone
 * @param name
 * @param textFieldProps
 * @param props
 * @constructor
 */
export const DatePickerWithTimezone = (
    {
        selected,
        onChange,
        timezone,
        name,
        textFieldProps,
        ...props
    }: ComponentProps<typeof ReactDatePicker> & {
        timezone?: string,
        textFieldProps?: BaseTextFieldProps,
    }) => (
    <Fragment>
        {!!timezone &&
            <ReactDatePicker
                name={name}
                selected={selected ? setLocalTimeZone(selected, timezone) : null}
                onChange={(v, e) => {
                    onChange(v ? setRegionTimeZone(v as Date, timezone) : null, e)
                }}
                timeIntervals={15}
                showTimeInput
                dateFormat='MMMM d, yyyy h:mm aa'
                showTimeSelect
                customInput={
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        {...textFieldProps}
                    />
                }
                {...props}
            />
        }
    </Fragment>
)

/**
 * Transform date based on local timezone
 * @note Used by browser to display correct timezone
 * @param date
 * @param timezone
 */
const setLocalTimeZone = (date: Date, timezone: string) => {
    const dateWithoutZone = moment
        .tz(date, timezone)
        .format("YYYY-MM-DDTHH:mm:ss.SSS")
    const localZone = moment(dateWithoutZone).format("Z")
    const dateWithLocalZone = [dateWithoutZone, localZone].join("")

    return new Date(dateWithLocalZone)
}

/**
 * Transform date into any non-local timezone
 * @note User's timezone is not the timezone of the region
 * @param date
 * @param timezone
 */
const setRegionTimeZone = (date: Date, timezone: string) => {
    const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS")
    const otherZone = moment.tz(date, timezone).format("Z")
    const dateWithOtherZone = [dateWithoutZone, otherZone].join("")

    return new Date(dateWithOtherZone)
}
