import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../redux/reducers";
import React, { useEffect } from "react";
import { signIn } from "../redux/actions/authActions";
import AuthLoader from "./AuthLoader";

export interface AuthGuardType {
    children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
    const auth = useSelector((state: AppStateType) => state.authReducer);
    const { config } = useSelector((state: AppStateType) => state.configReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(signIn(config));
    }, []);

    if (auth.isAuthing) {
        return <AuthLoader isLoading={true} />;
    }
    if (!auth.isAuthing) {
        if (!auth.user) {
            window.location.href = config.sso.logoutUrl;
            return <AuthLoader isLoading={true} />;
        }
        if (!auth.user.isAdmin) {
            return <AuthLoader isLoading={false} />;
        }
    }

    return children;
}

export default AuthGuard;
