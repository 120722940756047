import { EventCreateDto } from '../apis/_generated/livestream';

export const EventTypes: {
  type: EventCreateDto.EventTypeEnum,
  label: string
}[] = [
  {
    type: EventCreateDto.EventTypeEnum.Livestream,
    label: "Live Stream"
  }, {
    type: EventCreateDto.EventTypeEnum.OnDemand,
    label: "On Demand"
  }

];
