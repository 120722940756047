import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { LiveStreamsDto } from '../../../apis/_generated/livestream';
import { getLiveStreamStatusLabel } from '../../../constants/liveStreamStatuses';
import { Chip } from '../../../components/Common';
import { red, grey } from '@material-ui/core/colors';
import { getLiveStreamOrientationLabel } from '../../../constants/liveStreamOrientations';
import { Edit, Trash2 } from 'react-feather';
import CopyComponent from '../../../components/CopyComponent';


const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset"
        }
    }
});

const LiveStreamListRow = (props: { row: LiveStreamsDto, editLiveStream: () => void, deleteLiveStream: () => void }) => {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell align={"left"}>{row.name}</TableCell>
                <TableCell align={"center"}>{getLiveStreamOrientationLabel(row.orientation)}</TableCell>
                <TableCell align={"center"}>{getLiveStreamStatusLabel(row.record as boolean)}</TableCell>
                <TableCell align={"center"}>{getLiveStreamStatusLabel(row._public as boolean)}</TableCell>
                <TableCell align={"center"}>
                    <Chip
                        label={getLiveStreamStatusLabel(row.broadcasting as boolean)}
                        rgbcolor={row.broadcasting ? red[500] : grey[500]}
                    />
                </TableCell>
                <TableCell align="right" width={"100px"}>
                    <IconButton aria-label="edit a livestream" size="small"
                                            onClick={() => props.editLiveStream()}><Edit /></IconButton>
                    <IconButton aria-label="delete" size="small" onClick={() => props.deleteLiveStream()}><Trash2 fontSize="4" /></IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Details:
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={"300px"}>{row.name}</TableCell>
                                        <TableCell width={"10em"}>Thumbnail</TableCell>
                                        <TableCell>Broadcast info</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {row.assets?.iframe && (
                                                <div style={{ height: "200px" }} dangerouslySetInnerHTML={{ __html: row.assets?.iframe }} />)}
                                        </TableCell>
                                        <TableCell valign={"top"}>
                                            <img src={row.assets?.thumbnail} alt="" style={{
                                                backgroundImage: "url(\"/static/img/video/no-thumbnail.png\")",
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                maxWidth: "100%",
                                                minWidth: "10em"
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <p>
                                                <CopyComponent value={"rtmp://broadcast.api.video/s/" + row.streamKey} />
                                                <strong>RTMP Stream URL</strong>: <code>
                                                {"rtmp://broadcast.api.video/s/" + row.streamKey}
                                            </code>
                                            </p>
                                            <p>
                                                <CopyComponent value={row.liveStreamId} />
                                                <strong>Live stream ID</strong>: <code>{row.liveStreamId}</code>
                                            </p>
                                            <p>
                                                <CopyComponent value={row.streamKey || ""} />
                                                <strong>Stream Key</strong>: <code>{row.streamKey}</code>
                                            </p>
                                            <p>
                                                <CopyComponent value={row.assets?.player || ""} />
                                                <strong>Player URL</strong>: <code>{row.assets?.player}</code>
                                            </p>
                                            <p>
                                                <CopyComponent value={row.assets?.hls || ""} />
                                                <strong>HLS</strong>: <code>{row.assets?.hls}</code>
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};
export default LiveStreamListRow;
