import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { CircularProgress, AuthLoaderBlock, IconButton } from './Common';
import { signOut } from '../redux/actions/authActions';
import { useDispatch } from 'react-redux';
import { Power } from'react-feather';

const AuthLoader = (props: { isLoading: boolean }) => {
    return (
        <AuthLoaderBlock>
          { props.isLoading ? (<CircularProgress size={50} />) : (<UnauthenticatedPage />)}
        </AuthLoaderBlock>
    );
}

const UnauthenticatedPage = () => {
  const dispatch = useDispatch();

    async function handleSignOut() {
      await dispatch(signOut());
    }

    return <div>
      <h2>User authentication failed</h2>
      <p>Make sure you have permission to access Event Manager.</p>
        <IconButton
          aria-owns="menu-appbar"
          aria-haspopup="true"
          onClick={handleSignOut}
          color="inherit"
        >
          <Power />
        </IconButton>
    </div>
}

export default AuthLoader;
