import React, { useState } from 'react';
import { IconButton, TextField } from './Common';
import { InputAdornment, Tooltip } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FileCopy } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

export const TextFieldWithCopyComponent = (props: { label: string, value: string | undefined }) => {
    return (
        <TextField
            label={props.label}
            value={props.value}
            fullWidth
            disabled
            variant='outlined'
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <CopyComponent value={props.value || ''} />
                    </InputAdornment>
                )
            }}
        />
    );

}

const CopyComponent = (props: { value: string }) => {
    const [open, setOpen] = useState(false);

    return (<CopyToClipboard text={props.value} onCopy={() => setOpen(true)}>
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied"
            placement="top"
            onMouseLeave={() => setOpen(false)}
        >
            <IconButton style={{ color: open ? grey[200] : grey[500] }} >
                <FileCopy fontSize="small" />
            </IconButton>
        </Tooltip>
    </CopyToClipboard>);
}


export default CopyComponent;
