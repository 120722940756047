import React from 'react';
import { BigImage, Button, CenteredContent, CloudUpload, Grid, Typography } from './Common';
import { CropImageInterface } from './CropDialog';

interface ImageUploadProps {
    form: any,
    image: CropImageInterface,
    handleChange: (e: any) => unknown
}

const ImageUpload = (props: ImageUploadProps) => {
    const imageId = props.image.key.toString();
    return (<Grid item xs={6}>
        <CenteredContent>
            <Typography
                variant="h6"
                gutterBottom
                color={props.form.touched[imageId] && props.form.errors[imageId] && ( 'error' )}
            >
                {props.image.name}
            </Typography>
            <BigImage
                src={props.image.value ? props.image.value as string : props.image.placeholder}
            />
            <input
                accept="image/*"
                style={{display: "none"}}
                id={imageId}
                type="file"
                onChange={props.handleChange}
            />
            <label htmlFor={props.image.key as string}>
                <Button
                    variant="contained"
                    color="primary"
                    component="span"
                >
                    <CloudUpload mr={2}/>
                    Upload
                </Button>

                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                >
                    {props.image.description}
                </Typography>
                {props.form.touched[imageId] && props.form.errors[imageId] && (
                    <Typography
                        variant="caption"
                        display="block"
                        color="error"
                        gutterBottom>{props.form.errors[imageId]}</Typography>
                )}
            </label>
        </CenteredContent>
    </Grid>);
};

export default ImageUpload;
