export enum VideoLoginRequiredEnum {
    no = 'no',
    yes = 'yes',
}

export const VideoLoginRequired:  {
  type: VideoLoginRequiredEnum,
  label: string
}[] = [
  {
    type: VideoLoginRequiredEnum.no,
    label: 'No'
  },
  {
    type: VideoLoginRequiredEnum.yes,
    label: 'Yes'
  },
];
