import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { CircularProgress, Divider } from './Common';
import { SaveableEvent } from '../pages/events/EventForm';

export interface CropImageInterface {
  key: keyof SaveableEvent;
  name: string;
  value: string | null;
  previewSource?: string;
  aspect: number;
  placeholder: string;
  description: string;
  section?: string;
}

interface CropDialogInterface {
  image: CropImageInterface | null;
  setImage: (image: CropImageInterface | null) => void;
  handleUpload: (image: string) => void;
  isUploadingImage: boolean;
}

const CropDialog = (props: CropDialogInterface) => {
  const [open, setOpen] = useState(false);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [crop, setCrop] = useState<Crop>({ unit: '%', width: 30, aspect: props.image?.aspect ?? 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  // Draw new cropped image
  useEffect(() => {
    const image = imgRef.current as unknown as HTMLImageElement;
    const canvas = previewCanvasRef.current as HTMLCanvasElement;
    const crop = completedCrop as Crop;

    if (!image || !canvas || !crop) {
      return;
    }

    if (!canvas.getContext('2d')) {
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const pixelRatio = window.devicePixelRatio;

    canvas.width = (crop.width ?? 0) * pixelRatio;
    canvas.height = (crop.height ?? 0) * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      (crop.x ?? 0) * scaleX,
      (crop.y ?? 0) * scaleY,
      (crop.width ?? 0) * scaleX,
      (crop.height ?? 0) * scaleY,
      0,
      0,
      crop.width ?? 0,
      crop.height ?? 0
    );
  }, [completedCrop]);

  useEffect(() => {
    setOpen(!!props.image ?? false);
  }, [props.image])

  function handleUpload() {
    const canvas = previewCanvasRef.current as HTMLCanvasElement;
    props.handleUpload(canvas.toDataURL());
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{ props.image?.name ?? "Crop Image VideoDialog" }</DialogTitle>
      <DialogContent>
        {props.image?.previewSource && (
          <ReactCrop
            src={props.image.previewSource}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
        )}
        <DialogContentText>
          <Divider my={6} />
          <small>Preview:</small>
          <div>
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0)
              }}
            />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setImage(null) } color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleUpload() } disabled={props.isUploadingImage} color="primary">
          <span>Upload{props.isUploadingImage && (<>ing <CircularProgress size={20} /></>)}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CropDialog;
