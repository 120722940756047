import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { LiveStreamsDto } from '../../../apis/_generated/livestream';
import { EventManagerService } from '../../../services/eventManagerService';
import { useSelector } from 'react-redux';
import { AppStateType } from '../../../redux/reducers';
import { SaveableLiveStream } from './LiveStreamDialog';
import LiveStreamListRow from './LiveStreamListRow';

interface LiveStreamsProps {
    livestreams: LiveStreamsDto[] | undefined,
    handleOpen: (video: SaveableLiveStream) => void,
    handleError: (error: string) => void,
    handleSuccess: (success: string) => void,
}

const LiveStreamListTable = (props: LiveStreamsProps) => {
    const { config } = useSelector((state: AppStateType) => state.configReducer);
    const [liveStreams, setLiveStreams] = useState<LiveStreamsDto[] | undefined>([]);

    useEffect(() => {
        setLiveStreams(props.livestreams);
    }, [props.livestreams])

    const handleDeleteLiveStream = (id: number): void => {
        const eventManagerService = new EventManagerService({ basePath: config.resources.livestream.endpoint });
        eventManagerService.deleteLivestream(id)
            .then(() => {
                // @note Get new livestream list without the deleted one;
                setLiveStreams(liveStreams?.filter(item => item.id !== id));
                props.handleSuccess('LiveStream successfully deleted');
            })
            .catch((error) => {
                props.handleError('LiveStream failed to delete: ' + (error.message ?? error.statusText));
            });
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell align={"center"}>Video Orientation</TableCell>
                        <TableCell align={"center"}>Record</TableCell>
                        <TableCell align={"center"}>Public</TableCell>
                        <TableCell align={"center"}>Broadcasting</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {liveStreams?.map((livestream) => (
                        <LiveStreamListRow key={livestream.id} row={livestream} editLiveStream={() => {
                            props.handleOpen(livestream);
                        }} deleteLiveStream={() => handleDeleteLiveStream(livestream.id)} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default LiveStreamListTable;
