import { combineReducers } from 'redux';

import themeReducer from './themeReducer';
import authReducer from './authReducer';
import configReducer from './configReducer';

export const rootReducer = combineReducers({
    themeReducer,
    authReducer,
    configReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
