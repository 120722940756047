import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./mocks";

import { Provider } from "react-redux";
import store from "./redux/store/index";
import { setConfig } from "./redux/actions/configActions";

fetch("/config.json")
    .then((data) => data.json())
    .then((config) => {
        // @see AS-4684 Set config to reducer
        store.dispatch(setConfig(config));
        ReactDOM.render(
            <Provider store={store}>
                <App />
            </Provider>,
          document.getElementById("root")
    );
})
.catch((e) => {
    console.error("Impossible to retrieve configuration");
});
