/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Event Manager API
 * Event Manager
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://events.apollo.audio/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface CreateVideoDto
 */
export interface CreateVideoDto {
    /**
     * 
     * @type {string}
     * @memberof CreateVideoDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVideoDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVideoDto
     */
    source?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateVideoDto
     */
    _public?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateVideoDto
     */
    panoramic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateVideoDto
     */
    mp4Support?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateVideoDto
     */
    playerId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateVideoDto
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<Metadata>}
     * @memberof CreateVideoDto
     */
    metadata?: Array<Metadata>;
    /**
     * 
     * @type {Date}
     * @memberof CreateVideoDto
     */
    publishedAt?: Date;
}
/**
 * 
 * @export
 * @interface DataResponse
 */
export interface DataResponse {
    /**
     * 
     * @type {Array<EventOutDto>}
     * @memberof DataResponse
     */
    items: Array<EventOutDto>;
    /**
     * 
     * @type {DataResponsePagination}
     * @memberof DataResponse
     */
    meta: DataResponsePagination;
    /**
     * 
     * @type {DataResponseLinks}
     * @memberof DataResponse
     */
    links: DataResponseLinks;
}
/**
 * 
 * @export
 * @interface DataResponseLinks
 */
export interface DataResponseLinks {
    /**
     * 
     * @type {string}
     * @memberof DataResponseLinks
     */
    first: string;
    /**
     * 
     * @type {string}
     * @memberof DataResponseLinks
     */
    previous: string;
    /**
     * 
     * @type {string}
     * @memberof DataResponseLinks
     */
    next: string;
    /**
     * 
     * @type {string}
     * @memberof DataResponseLinks
     */
    last: string;
}
/**
 * 
 * @export
 * @interface DataResponsePagination
 */
export interface DataResponsePagination {
    /**
     * Total number of items
     * @type {number}
     * @memberof DataResponsePagination
     */
    totalItems: number;
    /**
     * Number of items in current request
     * @type {number}
     * @memberof DataResponsePagination
     */
    itemCount: number;
    /**
     * Number of items per page
     * @type {number}
     * @memberof DataResponsePagination
     */
    itemsPerPage: number;
    /**
     * The current page
     * @type {number}
     * @memberof DataResponsePagination
     */
    currentPage: number;
    /**
     * Total number of pages
     * @type {number}
     * @memberof DataResponsePagination
     */
    totalPages: number;
}
/**
 * 
 * @export
 * @interface EventCreateDto
 */
export interface EventCreateDto {
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    eventType: EventCreateDto.EventTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventCreateDto
     */
    stationIds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventCreateDto
     */
    regionIds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventCreateDto
     */
    appIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof EventCreateDto
     */
    startAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventCreateDto
     */
    endAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventCreateDto
     */
    liveStartAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventCreateDto
     */
    liveEndAt: Date;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    imageHeroSmall: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    imageHeroLarge: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    highlightColour: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventCreateDto
     */
    isLoginRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventCreateDto
     */
    isFeaturedEvent: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    slug: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventCreateDto
     */
    isPremiumOnly: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventCreateDto
     */
    isLivePopupEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    livePopupTitle: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    livePopupDescription: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    livePopupButtonText: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    livePopupButtonUrl: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    livePopupImageHero: string;
}

/**
 * @export
 * @namespace EventCreateDto
 */
export namespace EventCreateDto {
    /**
     * @export
     * @enum {string}
     */
    export enum EventTypeEnum {
        Livestream = <any> 'livestream',
        OnDemand = <any> 'onDemand'
    }
}
/**
 * 
 * @export
 * @interface EventOutDto
 */
export interface EventOutDto {
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    eventType: EventOutDto.EventTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventOutDto
     */
    regionIds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventOutDto
     */
    stationIds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventOutDto
     */
    appIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof EventOutDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof EventOutDto
     */
    startAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventOutDto
     */
    endAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventOutDto
     */
    liveStartAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventOutDto
     */
    liveEndAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventOutDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventOutDto
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    imageHeroSmall: string;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    imageHeroLarge: string;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    highlightColour: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventOutDto
     */
    isLoginRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventOutDto
     */
    isFeaturedEvent: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    slug: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventOutDto
     */
    isPremiumOnly: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    status: EventOutDto.StatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EventOutDto
     */
    isLivePopupEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    livePopupTitle: string;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    livePopupDescription: string;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    livePopupButtonText: string;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    livePopupButtonUrl: string;
    /**
     * 
     * @type {string}
     * @memberof EventOutDto
     */
    livePopupImageHero: string;
    /**
     * 
     * @type {Array<EventsShuttlelinksDto>}
     * @memberof EventOutDto
     */
    eventsShuttlelinks: Array<EventsShuttlelinksDto>;
    /**
     * 
     * @type {Array<VideoDto>}
     * @memberof EventOutDto
     */
    videos?: Array<VideoDto>;
    /**
     * 
     * @type {Array<LiveStreamsDto>}
     * @memberof EventOutDto
     */
    eventsLivestreams: Array<LiveStreamsDto>;
}

/**
 * @export
 * @namespace EventOutDto
 */
export namespace EventOutDto {
    /**
     * @export
     * @enum {string}
     */
    export enum EventTypeEnum {
        Livestream = <any> 'livestream',
        OnDemand = <any> 'onDemand'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Publish = <any> 'publish',
        Draft = <any> 'draft'
    }
}
/**
 * 
 * @export
 * @interface EventPatchDto
 */
export interface EventPatchDto {
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    eventType?: EventPatchDto.EventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    title?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventPatchDto
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPatchDto
     */
    endAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPatchDto
     */
    liveStartAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPatchDto
     */
    liveEndAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    imageHeroSmall?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    imageHeroLarge?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    highlightColour?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPatchDto
     */
    isLoginRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventPatchDto
     */
    isFeaturedEvent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    slug?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPatchDto
     */
    isPremiumOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventPatchDto
     */
    isLivePopupEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    livePopupTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    livePopupDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    livePopupButtonText?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    livePopupButtonUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPatchDto
     */
    livePopupImageHero?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventPatchDto
     */
    stationIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventPatchDto
     */
    regionIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventPatchDto
     */
    appIds?: Array<number>;
}

/**
 * @export
 * @namespace EventPatchDto
 */
export namespace EventPatchDto {
    /**
     * @export
     * @enum {string}
     */
    export enum EventTypeEnum {
        Livestream = <any> 'livestream',
        OnDemand = <any> 'onDemand'
    }
}
/**
 * 
 * @export
 * @interface EventsShuttlelinksDto
 */
export interface EventsShuttlelinksDto {
    /**
     * 
     * @type {number}
     * @memberof EventsShuttlelinksDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EventsShuttlelinksDto
     */
    eventId: number;
    /**
     * 
     * @type {number}
     * @memberof EventsShuttlelinksDto
     */
    regionId: number;
    /**
     * 
     * @type {number}
     * @memberof EventsShuttlelinksDto
     */
    appId: number;
    /**
     * 
     * @type {number}
     * @memberof EventsShuttlelinksDto
     */
    stationId: number;
    /**
     * 
     * @type {number}
     * @memberof EventsShuttlelinksDto
     */
    shuttlelinkId: number;
    /**
     * 
     * @type {string}
     * @memberof EventsShuttlelinksDto
     */
    shuttlelinkUrl: string;
}
/**
 * 
 * @export
 * @interface FileUploadDto
 */
export interface FileUploadDto {
    /**
     * 
     * @type {any}
     * @memberof FileUploadDto
     */
    file: any;
}
/**
 * 
 * @export
 * @interface ImageDto
 */
export interface ImageDto {
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface ImageUploadDto
 */
export interface ImageUploadDto {
    /**
     * 
     * @type {string}
     * @memberof ImageUploadDto
     */
    base64EncodedImage: string;
}
/**
 * 
 * @export
 * @interface LiveStreamAssetsDto
 */
export interface LiveStreamAssetsDto {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAssetsDto
     */
    hls?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAssetsDto
     */
    iframe?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAssetsDto
     */
    player?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAssetsDto
     */
    thumbnail?: string;
}
/**
 * 
 * @export
 * @interface LiveStreamCreateDto
 */
export interface LiveStreamCreateDto {
    /**
     * 
     * @type {number}
     * @memberof LiveStreamCreateDto
     */
    eventId: number;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamCreateDto
     */
    orientation: LiveStreamCreateDto.OrientationEnum;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamCreateDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamCreateDto
     */
    record?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamCreateDto
     */
    _public?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamCreateDto
     */
    playerId?: string;
}

/**
 * @export
 * @namespace LiveStreamCreateDto
 */
export namespace LiveStreamCreateDto {
    /**
     * @export
     * @enum {string}
     */
    export enum OrientationEnum {
        Landscape = <any> 'landscape',
        Portrait = <any> 'portrait'
    }
}
/**
 * 
 * @export
 * @interface LiveStreamPatchDto
 */
export interface LiveStreamPatchDto {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamPatchDto
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamPatchDto
     */
    record?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamPatchDto
     */
    _public?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamPatchDto
     */
    playerId?: string;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamPatchDto
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamPatchDto
     */
    orientation?: LiveStreamPatchDto.OrientationEnum;
}

/**
 * @export
 * @namespace LiveStreamPatchDto
 */
export namespace LiveStreamPatchDto {
    /**
     * @export
     * @enum {string}
     */
    export enum OrientationEnum {
        Landscape = <any> 'landscape',
        Portrait = <any> 'portrait'
    }
}
/**
 * 
 * @export
 * @interface LiveStreamsDto
 */
export interface LiveStreamsDto {
    /**
     * The unique, private stream key that you use to begin streaming. Will be blank if requester is not logged into Events Admin
     * @type {string}
     * @memberof LiveStreamsDto
     */
    streamKey?: string;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamsDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamsDto
     */
    eventId: number;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsDto
     */
    orientation: LiveStreamsDto.OrientationEnum;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsDto
     */
    liveStreamId: string;
    /**
     * 
     * @type {LiveStreamAssetsDto}
     * @memberof LiveStreamsDto
     */
    assets?: LiveStreamAssetsDto;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamsDto
     */
    broadcasting?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof LiveStreamsDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LiveStreamsDto
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamsDto
     */
    record?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamsDto
     */
    _public?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsDto
     */
    playerId?: string;
}

/**
 * @export
 * @namespace LiveStreamsDto
 */
export namespace LiveStreamsDto {
    /**
     * @export
     * @enum {string}
     */
    export enum OrientationEnum {
        Landscape = <any> 'landscape',
        Portrait = <any> 'portrait'
    }
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    value: string;
}
/**
 * 
 * @export
 * @interface SlugOutDto
 */
export interface SlugOutDto {
    /**
     * 
     * @type {boolean}
     * @memberof SlugOutDto
     */
    status: boolean;
    /**
     * 
     * @type {string}
     * @memberof SlugOutDto
     */
    message: string;
}
/**
 * 
 * @export
 * @interface UpdateVideoDto
 */
export interface UpdateVideoDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoDto
     */
    source?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateVideoDto
     */
    _public?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateVideoDto
     */
    panoramic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateVideoDto
     */
    mp4Support?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoDto
     */
    playerId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateVideoDto
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<Metadata>}
     * @memberof UpdateVideoDto
     */
    metadata?: Array<Metadata>;
}
/**
 * 
 * @export
 * @interface UploadTokenDto
 */
export interface UploadTokenDto {
    /**
     * 
     * @type {string}
     * @memberof UploadTokenDto
     */
    token?: string;
    /**
     * 
     * @type {number}
     * @memberof UploadTokenDto
     */
    ttl?: number;
    /**
     * 
     * @type {Date}
     * @memberof UploadTokenDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UploadTokenDto
     */
    expiresAt?: Date;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    lastName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    roles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    permissions: Array<string>;
}
/**
 * 
 * @export
 * @interface VideoAssets
 */
export interface VideoAssets {
    /**
     * 
     * @type {string}
     * @memberof VideoAssets
     */
    hls: string;
    /**
     * 
     * @type {string}
     * @memberof VideoAssets
     */
    iframe: string;
    /**
     * 
     * @type {string}
     * @memberof VideoAssets
     */
    player: string;
    /**
     * 
     * @type {string}
     * @memberof VideoAssets
     */
    thumbnail: string;
    /**
     * 
     * @type {string}
     * @memberof VideoAssets
     */
    mp4: string;
}
/**
 * 
 * @export
 * @interface VideoDto
 */
export interface VideoDto {
    /**
     * 
     * @type {number}
     * @memberof VideoDto
     */
    duration: number;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    videoId: string;
    /**
     * 
     * @type {Date}
     * @memberof VideoDto
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    publishedAt: string;
    /**
     * 
     * @type {Date}
     * @memberof VideoDto
     */
    updatedAt: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof VideoDto
     */
    tags: Array<string>;
    /**
     * 
     * @type {Array<Metadata>}
     * @memberof VideoDto
     */
    metadata: Array<Metadata>;
    /**
     * 
     * @type {VideoSource}
     * @memberof VideoDto
     */
    source: VideoSource;
    /**
     * 
     * @type {VideoAssets}
     * @memberof VideoDto
     */
    assets: VideoAssets;
    /**
     * 
     * @type {string}
     * @memberof VideoDto
     */
    playerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof VideoDto
     */
    _public: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VideoDto
     */
    panoramic: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VideoDto
     */
    mp4Support: boolean;
}
/**
 * 
 * @export
 * @interface VideoSource
 */
export interface VideoSource {
    /**
     * 
     * @type {string}
     * @memberof VideoSource
     */
    uri: string;
    /**
     * 
     * @type {string}
     * @memberof VideoSource
     */
    type: string;
    /**
     * 
     * @type {VideoSourceLiveStream}
     * @memberof VideoSource
     */
    liveStream: VideoSourceLiveStream;
}
/**
 * 
 * @export
 * @interface VideoSourceLiveStream
 */
export interface VideoSourceLiveStream {
    /**
     * 
     * @type {string}
     * @memberof VideoSourceLiveStream
     */
    liveStreamId: string;
    /**
     * 
     * @type {Array<VideoSourceLiveStreamLink>}
     * @memberof VideoSourceLiveStream
     */
    links: Array<VideoSourceLiveStreamLink>;
}
/**
 * 
 * @export
 * @interface VideoSourceLiveStreamLink
 */
export interface VideoSourceLiveStreamLink {
    /**
     * 
     * @type {string}
     * @memberof VideoSourceLiveStreamLink
     */
    rel: string;
    /**
     * 
     * @type {string}
     * @memberof VideoSourceLiveStreamLink
     */
    uri: string;
}
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Make login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(options: any = {}): FetchArgs {
            const localVarPath = `/api/saml2/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make logout request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogout(options: any = {}): FetchArgs {
            const localVarPath = `/api/saml2/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get logged user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Make login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authLogin(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Make logout request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authLogout(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get logged user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).getUser(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Make login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(options?: any) {
            return AuthApiFp(configuration).authLogin(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Make logout request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogout(options?: any) {
            return AuthApiFp(configuration).authLogout(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get logged user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any) {
            return AuthApiFp(configuration).getUser(options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
    /**
     * 
     * @summary Make login request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authLogin(options?: any): Promise<any>;

    /**
     * 
     * @summary Make logout request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authLogout(options?: any): Promise<{}>;

    /**
     * 
     * @summary Get logged user details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getUser(options?: any): Promise<UserDto>;

}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
    /**
     * 
     * @summary Make login request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogin(options?: any) {
        return AuthApiFp(this.configuration).authLogin(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Make logout request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogout(options?: any) {
        return AuthApiFp(this.configuration).authLogout(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get logged user details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getUser(options?: any) {
        return AuthApiFp(this.configuration).getUser(options)(this.fetch, this.basePath);
    }

}
/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckControllerCheck(options: any = {}): FetchArgs {
            const localVarPath = `/api/healthcheck`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckControllerCheck(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).healthCheckControllerCheck(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckControllerCheck(options?: any) {
            return DefaultApiFp(configuration).healthCheckControllerCheck(options)(fetch, basePath);
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    healthCheckControllerCheck(options?: any): Promise<string>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthCheckControllerCheck(options?: any) {
        return DefaultApiFp(this.configuration).healthCheckControllerCheck(options)(this.fetch, this.basePath);
    }

}
/**
 * EventManagerApi - fetch parameter creator
 * @export
 */
export const EventManagerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Make login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(options: any = {}): FetchArgs {
            const localVarPath = `/api/saml2/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make logout request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogout(options: any = {}): FetchArgs {
            const localVarPath = `/api/saml2/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create event
         * @param {EventCreateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(body: EventCreateDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createEvent.');
            }
            const localVarPath = `/api/v1/events`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EventCreateDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create livestream
         * @param {LiveStreamCreateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLivestream(body: LiveStreamCreateDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLivestream.');
            }
            const localVarPath = `/api/v1/livestreams`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LiveStreamCreateDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new video
         * @param {CreateVideoDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideo(body: CreateVideoDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createVideo.');
            }
            const localVarPath = `/api/v1/videos`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateVideoDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteEvent.');
            }
            const localVarPath = `/api/v1/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete livestream
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLivestream(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLivestream.');
            }
            const localVarPath = `/api/v1/livestreams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideo(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteVideo.');
            }
            const localVarPath = `/api/v1/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Get a delegated video upload token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelegatedToken(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/videos/upload-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEvent.');
            }
            const localVarPath = `/api/v1/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all events
         * @param {string} [title] Filter by event title
         * @param {Array<string>} [regionCode] Filter by region code (regionId performs better)
         * @param {Array<string>} [stationCode] Filter by station code (stationId performs better)
         * @param {Array<string>} [appCode] Filter by app code (appId performs better)
         * @param {Array<number>} [regionId] Filter by region ID (Performs better than regionCode)
         * @param {Array<number>} [stationId] Filter by station ID (Performs better than stationCode)
         * @param {Array<number>} [appId] Filter by app ID (Performs better than appCode)
         * @param {string} [_o] Apply a sort to the events
         * @param {number} [_p] Pagination: Page number
         * @param {number} [_pp] Pagination: Items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(title?: string, regionCode?: Array<string>, stationCode?: Array<string>, appCode?: Array<string>, regionId?: Array<number>, stationId?: Array<number>, appId?: Array<number>, _o?: string, _p?: number, _pp?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/events`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (regionCode) {
                localVarQueryParameter['regionCode'] = regionCode;
            }

            if (stationCode) {
                localVarQueryParameter['stationCode'] = stationCode;
            }

            if (appCode) {
                localVarQueryParameter['appCode'] = appCode;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (stationId) {
                localVarQueryParameter['stationId'] = stationId;
            }

            if (appId) {
                localVarQueryParameter['appId'] = appId;
            }

            if (_o !== undefined) {
                localVarQueryParameter['_o'] = _o;
            }

            if (_p !== undefined) {
                localVarQueryParameter['_p'] = _p;
            }

            if (_pp !== undefined) {
                localVarQueryParameter['_pp'] = _pp;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get logged user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideo(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVideo.');
            }
            const localVarPath = `/api/v1/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update event
         * @param {EventPatchDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(body: EventPatchDto, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateEvent.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateEvent.');
            }
            const localVarPath = `/api/v1/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EventPatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update livestream
         * @param {LiveStreamPatchDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLivestream(body: LiveStreamPatchDto, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateLivestream.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateLivestream.');
            }
            const localVarPath = `/api/v1/livestreams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LiveStreamPatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a video
         * @param {UpdateVideoDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVideo(body: UpdateVideoDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateVideo.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateVideo.');
            }
            const localVarPath = `/api/v1/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateVideoDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload image to cloudinary
         * @param {ImageUploadDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(body: ImageUploadDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling uploadImage.');
            }
            const localVarPath = `/api/v1/images`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ImageUploadDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Upload livestream thumbnail
         * @param {any} file 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLivestreamThumbnail(file: any, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadLivestreamThumbnail.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadLivestreamThumbnail.');
            }
            const localVarPath = `/api/v1/livestreams/thumbnail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Upload video thumbnail
         * @param {any} file 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideoThumbnail(file: any, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadVideoThumbnail.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadVideoThumbnail.');
            }
            const localVarPath = `/api/v1/videos/thumbnail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if an event slug is valid
         * @param {string} slug 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSlug(slug: string, id?: number, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling validateSlug.');
            }
            const localVarPath = `/api/v1/events/slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventManagerApi - functional programming interface
 * @export
 */
export const EventManagerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Make login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).authLogin(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Make logout request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).authLogout(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create event
         * @param {EventCreateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(body: EventCreateDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EventOutDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).createEvent(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create livestream
         * @param {LiveStreamCreateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLivestream(body: LiveStreamCreateDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LiveStreamsDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).createLivestream(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create a new video
         * @param {CreateVideoDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideo(body: CreateVideoDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VideoDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).createVideo(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).deleteEvent(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete livestream
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLivestream(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).deleteLivestream(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideo(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).deleteVideo(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Get a delegated video upload token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelegatedToken(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadTokenDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).getDelegatedToken(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EventOutDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).getEvent(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all events
         * @param {string} [title] Filter by event title
         * @param {Array<string>} [regionCode] Filter by region code (regionId performs better)
         * @param {Array<string>} [stationCode] Filter by station code (stationId performs better)
         * @param {Array<string>} [appCode] Filter by app code (appId performs better)
         * @param {Array<number>} [regionId] Filter by region ID (Performs better than regionCode)
         * @param {Array<number>} [stationId] Filter by station ID (Performs better than stationCode)
         * @param {Array<number>} [appId] Filter by app ID (Performs better than appCode)
         * @param {string} [_o] Apply a sort to the events
         * @param {number} [_p] Pagination: Page number
         * @param {number} [_pp] Pagination: Items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(title?: string, regionCode?: Array<string>, stationCode?: Array<string>, appCode?: Array<string>, regionId?: Array<number>, stationId?: Array<number>, appId?: Array<number>, _o?: string, _p?: number, _pp?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataResponse> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).getEvents(title, regionCode, stationCode, appCode, regionId, stationId, appId, _o, _p, _pp, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get logged user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).getUser(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideo(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VideoDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).getVideo(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update event
         * @param {EventPatchDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(body: EventPatchDto, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EventOutDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).updateEvent(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update livestream
         * @param {LiveStreamPatchDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLivestream(body: LiveStreamPatchDto, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LiveStreamsDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).updateLivestream(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update a video
         * @param {UpdateVideoDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVideo(body: UpdateVideoDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VideoDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).updateVideo(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Upload image to cloudinary
         * @param {ImageUploadDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(body: ImageUploadDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ImageDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).uploadImage(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Upload livestream thumbnail
         * @param {any} file 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLivestreamThumbnail(file: any, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LiveStreamsDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).uploadLivestreamThumbnail(file, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Upload video thumbnail
         * @param {any} file 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideoThumbnail(file: any, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VideoDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).uploadVideoThumbnail(file, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check if an event slug is valid
         * @param {string} slug 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSlug(slug: string, id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SlugOutDto> {
            const localVarFetchArgs = EventManagerApiFetchParamCreator(configuration).validateSlug(slug, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EventManagerApi - factory interface
 * @export
 */
export const EventManagerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Make login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(options?: any) {
            return EventManagerApiFp(configuration).authLogin(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Make logout request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogout(options?: any) {
            return EventManagerApiFp(configuration).authLogout(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create event
         * @param {EventCreateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(body: EventCreateDto, options?: any) {
            return EventManagerApiFp(configuration).createEvent(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create livestream
         * @param {LiveStreamCreateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLivestream(body: LiveStreamCreateDto, options?: any) {
            return EventManagerApiFp(configuration).createLivestream(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create a new video
         * @param {CreateVideoDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideo(body: CreateVideoDto, options?: any) {
            return EventManagerApiFp(configuration).createVideo(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(id: number, options?: any) {
            return EventManagerApiFp(configuration).deleteEvent(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete livestream
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLivestream(id: number, options?: any) {
            return EventManagerApiFp(configuration).deleteLivestream(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideo(id: string, options?: any) {
            return EventManagerApiFp(configuration).deleteVideo(id, options)(fetch, basePath);
        },
        /**
         * SimpleSaml auth required
         * @summary Get a delegated video upload token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelegatedToken(options?: any) {
            return EventManagerApiFp(configuration).getDelegatedToken(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(id: number, options?: any) {
            return EventManagerApiFp(configuration).getEvent(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all events
         * @param {string} [title] Filter by event title
         * @param {Array<string>} [regionCode] Filter by region code (regionId performs better)
         * @param {Array<string>} [stationCode] Filter by station code (stationId performs better)
         * @param {Array<string>} [appCode] Filter by app code (appId performs better)
         * @param {Array<number>} [regionId] Filter by region ID (Performs better than regionCode)
         * @param {Array<number>} [stationId] Filter by station ID (Performs better than stationCode)
         * @param {Array<number>} [appId] Filter by app ID (Performs better than appCode)
         * @param {string} [_o] Apply a sort to the events
         * @param {number} [_p] Pagination: Page number
         * @param {number} [_pp] Pagination: Items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(title?: string, regionCode?: Array<string>, stationCode?: Array<string>, appCode?: Array<string>, regionId?: Array<number>, stationId?: Array<number>, appId?: Array<number>, _o?: string, _p?: number, _pp?: number, options?: any) {
            return EventManagerApiFp(configuration).getEvents(title, regionCode, stationCode, appCode, regionId, stationId, appId, _o, _p, _pp, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get logged user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any) {
            return EventManagerApiFp(configuration).getUser(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideo(id: string, options?: any) {
            return EventManagerApiFp(configuration).getVideo(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update event
         * @param {EventPatchDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(body: EventPatchDto, id: number, options?: any) {
            return EventManagerApiFp(configuration).updateEvent(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update livestream
         * @param {LiveStreamPatchDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLivestream(body: LiveStreamPatchDto, id: number, options?: any) {
            return EventManagerApiFp(configuration).updateLivestream(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update a video
         * @param {UpdateVideoDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVideo(body: UpdateVideoDto, id: string, options?: any) {
            return EventManagerApiFp(configuration).updateVideo(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Upload image to cloudinary
         * @param {ImageUploadDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(body: ImageUploadDto, options?: any) {
            return EventManagerApiFp(configuration).uploadImage(body, options)(fetch, basePath);
        },
        /**
         * SimpleSaml auth required
         * @summary Upload livestream thumbnail
         * @param {any} file 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLivestreamThumbnail(file: any, id: number, options?: any) {
            return EventManagerApiFp(configuration).uploadLivestreamThumbnail(file, id, options)(fetch, basePath);
        },
        /**
         * SimpleSaml auth required
         * @summary Upload video thumbnail
         * @param {any} file 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideoThumbnail(file: any, id: string, options?: any) {
            return EventManagerApiFp(configuration).uploadVideoThumbnail(file, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check if an event slug is valid
         * @param {string} slug 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSlug(slug: string, id?: number, options?: any) {
            return EventManagerApiFp(configuration).validateSlug(slug, id, options)(fetch, basePath);
        },
    };
};

/**
 * EventManagerApi - interface
 * @export
 * @interface EventManagerApi
 */
export interface EventManagerApiInterface {
    /**
     * 
     * @summary Make login request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    authLogin(options?: any): Promise<any>;

    /**
     * 
     * @summary Make logout request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    authLogout(options?: any): Promise<{}>;

    /**
     * 
     * @summary Create event
     * @param {EventCreateDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    createEvent(body: EventCreateDto, options?: any): Promise<EventOutDto>;

    /**
     * 
     * @summary Create livestream
     * @param {LiveStreamCreateDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    createLivestream(body: LiveStreamCreateDto, options?: any): Promise<LiveStreamsDto>;

    /**
     * 
     * @summary Create a new video
     * @param {CreateVideoDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    createVideo(body: CreateVideoDto, options?: any): Promise<VideoDto>;

    /**
     * 
     * @summary Delete event
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    deleteEvent(id: number, options?: any): Promise<{}>;

    /**
     * 
     * @summary Delete livestream
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    deleteLivestream(id: number, options?: any): Promise<{}>;

    /**
     * 
     * @summary Delete a video
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    deleteVideo(id: string, options?: any): Promise<{}>;

    /**
     * SimpleSaml auth required
     * @summary Get a delegated video upload token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    getDelegatedToken(options?: any): Promise<UploadTokenDto>;

    /**
     * 
     * @summary Get event
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    getEvent(id: number, options?: any): Promise<EventOutDto>;

    /**
     * 
     * @summary Get all events
     * @param {string} [title] Filter by event title
     * @param {Array<string>} [regionCode] Filter by region code (regionId performs better)
     * @param {Array<string>} [stationCode] Filter by station code (stationId performs better)
     * @param {Array<string>} [appCode] Filter by app code (appId performs better)
     * @param {Array<number>} [regionId] Filter by region ID (Performs better than regionCode)
     * @param {Array<number>} [stationId] Filter by station ID (Performs better than stationCode)
     * @param {Array<number>} [appId] Filter by app ID (Performs better than appCode)
     * @param {string} [_o] Apply a sort to the events
     * @param {number} [_p] Pagination: Page number
     * @param {number} [_pp] Pagination: Items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    getEvents(title?: string, regionCode?: Array<string>, stationCode?: Array<string>, appCode?: Array<string>, regionId?: Array<number>, stationId?: Array<number>, appId?: Array<number>, _o?: string, _p?: number, _pp?: number, options?: any): Promise<DataResponse>;

    /**
     * 
     * @summary Get logged user details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    getUser(options?: any): Promise<UserDto>;

    /**
     * 
     * @summary Get a video
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    getVideo(id: string, options?: any): Promise<VideoDto>;

    /**
     * 
     * @summary Update event
     * @param {EventPatchDto} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    updateEvent(body: EventPatchDto, id: number, options?: any): Promise<EventOutDto>;

    /**
     * 
     * @summary Update livestream
     * @param {LiveStreamPatchDto} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    updateLivestream(body: LiveStreamPatchDto, id: number, options?: any): Promise<LiveStreamsDto>;

    /**
     * 
     * @summary Update a video
     * @param {UpdateVideoDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    updateVideo(body: UpdateVideoDto, id: string, options?: any): Promise<VideoDto>;

    /**
     * 
     * @summary Upload image to cloudinary
     * @param {ImageUploadDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    uploadImage(body: ImageUploadDto, options?: any): Promise<ImageDto>;

    /**
     * SimpleSaml auth required
     * @summary Upload livestream thumbnail
     * @param {any} file 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    uploadLivestreamThumbnail(file: any, id: number, options?: any): Promise<LiveStreamsDto>;

    /**
     * SimpleSaml auth required
     * @summary Upload video thumbnail
     * @param {any} file 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    uploadVideoThumbnail(file: any, id: string, options?: any): Promise<VideoDto>;

    /**
     * 
     * @summary Check if an event slug is valid
     * @param {string} slug 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApiInterface
     */
    validateSlug(slug: string, id?: number, options?: any): Promise<SlugOutDto>;

}

/**
 * EventManagerApi - object-oriented interface
 * @export
 * @class EventManagerApi
 * @extends {BaseAPI}
 */
export class EventManagerApi extends BaseAPI implements EventManagerApiInterface {
    /**
     * 
     * @summary Make login request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public authLogin(options?: any) {
        return EventManagerApiFp(this.configuration).authLogin(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Make logout request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public authLogout(options?: any) {
        return EventManagerApiFp(this.configuration).authLogout(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create event
     * @param {EventCreateDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public createEvent(body: EventCreateDto, options?: any) {
        return EventManagerApiFp(this.configuration).createEvent(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create livestream
     * @param {LiveStreamCreateDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public createLivestream(body: LiveStreamCreateDto, options?: any) {
        return EventManagerApiFp(this.configuration).createLivestream(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create a new video
     * @param {CreateVideoDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public createVideo(body: CreateVideoDto, options?: any) {
        return EventManagerApiFp(this.configuration).createVideo(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete event
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public deleteEvent(id: number, options?: any) {
        return EventManagerApiFp(this.configuration).deleteEvent(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete livestream
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public deleteLivestream(id: number, options?: any) {
        return EventManagerApiFp(this.configuration).deleteLivestream(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a video
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public deleteVideo(id: string, options?: any) {
        return EventManagerApiFp(this.configuration).deleteVideo(id, options)(this.fetch, this.basePath);
    }

    /**
     * SimpleSaml auth required
     * @summary Get a delegated video upload token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public getDelegatedToken(options?: any) {
        return EventManagerApiFp(this.configuration).getDelegatedToken(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get event
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public getEvent(id: number, options?: any) {
        return EventManagerApiFp(this.configuration).getEvent(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all events
     * @param {string} [title] Filter by event title
     * @param {Array<string>} [regionCode] Filter by region code (regionId performs better)
     * @param {Array<string>} [stationCode] Filter by station code (stationId performs better)
     * @param {Array<string>} [appCode] Filter by app code (appId performs better)
     * @param {Array<number>} [regionId] Filter by region ID (Performs better than regionCode)
     * @param {Array<number>} [stationId] Filter by station ID (Performs better than stationCode)
     * @param {Array<number>} [appId] Filter by app ID (Performs better than appCode)
     * @param {string} [_o] Apply a sort to the events
     * @param {number} [_p] Pagination: Page number
     * @param {number} [_pp] Pagination: Items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public getEvents(title?: string, regionCode?: Array<string>, stationCode?: Array<string>, appCode?: Array<string>, regionId?: Array<number>, stationId?: Array<number>, appId?: Array<number>, _o?: string, _p?: number, _pp?: number, options?: any) {
        return EventManagerApiFp(this.configuration).getEvents(title, regionCode, stationCode, appCode, regionId, stationId, appId, _o, _p, _pp, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get logged user details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public getUser(options?: any) {
        return EventManagerApiFp(this.configuration).getUser(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a video
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public getVideo(id: string, options?: any) {
        return EventManagerApiFp(this.configuration).getVideo(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update event
     * @param {EventPatchDto} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public updateEvent(body: EventPatchDto, id: number, options?: any) {
        return EventManagerApiFp(this.configuration).updateEvent(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update livestream
     * @param {LiveStreamPatchDto} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public updateLivestream(body: LiveStreamPatchDto, id: number, options?: any) {
        return EventManagerApiFp(this.configuration).updateLivestream(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update a video
     * @param {UpdateVideoDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public updateVideo(body: UpdateVideoDto, id: string, options?: any) {
        return EventManagerApiFp(this.configuration).updateVideo(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Upload image to cloudinary
     * @param {ImageUploadDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public uploadImage(body: ImageUploadDto, options?: any) {
        return EventManagerApiFp(this.configuration).uploadImage(body, options)(this.fetch, this.basePath);
    }

    /**
     * SimpleSaml auth required
     * @summary Upload livestream thumbnail
     * @param {any} file 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public uploadLivestreamThumbnail(file: any, id: number, options?: any) {
        return EventManagerApiFp(this.configuration).uploadLivestreamThumbnail(file, id, options)(this.fetch, this.basePath);
    }

    /**
     * SimpleSaml auth required
     * @summary Upload video thumbnail
     * @param {any} file 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public uploadVideoThumbnail(file: any, id: string, options?: any) {
        return EventManagerApiFp(this.configuration).uploadVideoThumbnail(file, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check if an event slug is valid
     * @param {string} slug 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerApi
     */
    public validateSlug(slug: string, id?: number, options?: any) {
        return EventManagerApiFp(this.configuration).validateSlug(slug, id, options)(this.fetch, this.basePath);
    }

}
/**
 * LivestreamsApi - fetch parameter creator
 * @export
 */
export const LivestreamsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create livestream
         * @param {LiveStreamCreateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLivestream(body: LiveStreamCreateDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLivestream.');
            }
            const localVarPath = `/api/v1/livestreams`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LiveStreamCreateDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete livestream
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLivestream(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLivestream.');
            }
            const localVarPath = `/api/v1/livestreams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update livestream
         * @param {LiveStreamPatchDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLivestream(body: LiveStreamPatchDto, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateLivestream.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateLivestream.');
            }
            const localVarPath = `/api/v1/livestreams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LiveStreamPatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Upload livestream thumbnail
         * @param {any} file 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLivestreamThumbnail(file: any, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadLivestreamThumbnail.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadLivestreamThumbnail.');
            }
            const localVarPath = `/api/v1/livestreams/thumbnail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LivestreamsApi - functional programming interface
 * @export
 */
export const LivestreamsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create livestream
         * @param {LiveStreamCreateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLivestream(body: LiveStreamCreateDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LiveStreamsDto> {
            const localVarFetchArgs = LivestreamsApiFetchParamCreator(configuration).createLivestream(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete livestream
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLivestream(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LivestreamsApiFetchParamCreator(configuration).deleteLivestream(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update livestream
         * @param {LiveStreamPatchDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLivestream(body: LiveStreamPatchDto, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LiveStreamsDto> {
            const localVarFetchArgs = LivestreamsApiFetchParamCreator(configuration).updateLivestream(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Upload livestream thumbnail
         * @param {any} file 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLivestreamThumbnail(file: any, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LiveStreamsDto> {
            const localVarFetchArgs = LivestreamsApiFetchParamCreator(configuration).uploadLivestreamThumbnail(file, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LivestreamsApi - factory interface
 * @export
 */
export const LivestreamsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create livestream
         * @param {LiveStreamCreateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLivestream(body: LiveStreamCreateDto, options?: any) {
            return LivestreamsApiFp(configuration).createLivestream(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete livestream
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLivestream(id: number, options?: any) {
            return LivestreamsApiFp(configuration).deleteLivestream(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update livestream
         * @param {LiveStreamPatchDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLivestream(body: LiveStreamPatchDto, id: number, options?: any) {
            return LivestreamsApiFp(configuration).updateLivestream(body, id, options)(fetch, basePath);
        },
        /**
         * SimpleSaml auth required
         * @summary Upload livestream thumbnail
         * @param {any} file 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLivestreamThumbnail(file: any, id: number, options?: any) {
            return LivestreamsApiFp(configuration).uploadLivestreamThumbnail(file, id, options)(fetch, basePath);
        },
    };
};

/**
 * LivestreamsApi - interface
 * @export
 * @interface LivestreamsApi
 */
export interface LivestreamsApiInterface {
    /**
     * 
     * @summary Create livestream
     * @param {LiveStreamCreateDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LivestreamsApiInterface
     */
    createLivestream(body: LiveStreamCreateDto, options?: any): Promise<LiveStreamsDto>;

    /**
     * 
     * @summary Delete livestream
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LivestreamsApiInterface
     */
    deleteLivestream(id: number, options?: any): Promise<{}>;

    /**
     * 
     * @summary Update livestream
     * @param {LiveStreamPatchDto} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LivestreamsApiInterface
     */
    updateLivestream(body: LiveStreamPatchDto, id: number, options?: any): Promise<LiveStreamsDto>;

    /**
     * SimpleSaml auth required
     * @summary Upload livestream thumbnail
     * @param {any} file 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LivestreamsApiInterface
     */
    uploadLivestreamThumbnail(file: any, id: number, options?: any): Promise<LiveStreamsDto>;

}

/**
 * LivestreamsApi - object-oriented interface
 * @export
 * @class LivestreamsApi
 * @extends {BaseAPI}
 */
export class LivestreamsApi extends BaseAPI implements LivestreamsApiInterface {
    /**
     * 
     * @summary Create livestream
     * @param {LiveStreamCreateDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LivestreamsApi
     */
    public createLivestream(body: LiveStreamCreateDto, options?: any) {
        return LivestreamsApiFp(this.configuration).createLivestream(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete livestream
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LivestreamsApi
     */
    public deleteLivestream(id: number, options?: any) {
        return LivestreamsApiFp(this.configuration).deleteLivestream(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update livestream
     * @param {LiveStreamPatchDto} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LivestreamsApi
     */
    public updateLivestream(body: LiveStreamPatchDto, id: number, options?: any) {
        return LivestreamsApiFp(this.configuration).updateLivestream(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * SimpleSaml auth required
     * @summary Upload livestream thumbnail
     * @param {any} file 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LivestreamsApi
     */
    public uploadLivestreamThumbnail(file: any, id: number, options?: any) {
        return LivestreamsApiFp(this.configuration).uploadLivestreamThumbnail(file, id, options)(this.fetch, this.basePath);
    }

}
/**
 * VideosApi - fetch parameter creator
 * @export
 */
export const VideosApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new video
         * @param {CreateVideoDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideo(body: CreateVideoDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createVideo.');
            }
            const localVarPath = `/api/v1/videos`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateVideoDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideo(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteVideo.');
            }
            const localVarPath = `/api/v1/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Get a delegated video upload token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelegatedToken(options: any = {}): FetchArgs {
            const localVarPath = `/api/v1/videos/upload-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideo(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVideo.');
            }
            const localVarPath = `/api/v1/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a video
         * @param {UpdateVideoDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVideo(body: UpdateVideoDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateVideo.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateVideo.');
            }
            const localVarPath = `/api/v1/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateVideoDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Upload video thumbnail
         * @param {any} file 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideoThumbnail(file: any, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadVideoThumbnail.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadVideoThumbnail.');
            }
            const localVarPath = `/api/v1/videos/thumbnail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideosApi - functional programming interface
 * @export
 */
export const VideosApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new video
         * @param {CreateVideoDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideo(body: CreateVideoDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VideoDto> {
            const localVarFetchArgs = VideosApiFetchParamCreator(configuration).createVideo(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideo(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VideosApiFetchParamCreator(configuration).deleteVideo(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Get a delegated video upload token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelegatedToken(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadTokenDto> {
            const localVarFetchArgs = VideosApiFetchParamCreator(configuration).getDelegatedToken(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideo(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VideoDto> {
            const localVarFetchArgs = VideosApiFetchParamCreator(configuration).getVideo(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update a video
         * @param {UpdateVideoDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVideo(body: UpdateVideoDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VideoDto> {
            const localVarFetchArgs = VideosApiFetchParamCreator(configuration).updateVideo(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * SimpleSaml auth required
         * @summary Upload video thumbnail
         * @param {any} file 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideoThumbnail(file: any, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VideoDto> {
            const localVarFetchArgs = VideosApiFetchParamCreator(configuration).uploadVideoThumbnail(file, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VideosApi - factory interface
 * @export
 */
export const VideosApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new video
         * @param {CreateVideoDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideo(body: CreateVideoDto, options?: any) {
            return VideosApiFp(configuration).createVideo(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideo(id: string, options?: any) {
            return VideosApiFp(configuration).deleteVideo(id, options)(fetch, basePath);
        },
        /**
         * SimpleSaml auth required
         * @summary Get a delegated video upload token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelegatedToken(options?: any) {
            return VideosApiFp(configuration).getDelegatedToken(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideo(id: string, options?: any) {
            return VideosApiFp(configuration).getVideo(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update a video
         * @param {UpdateVideoDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVideo(body: UpdateVideoDto, id: string, options?: any) {
            return VideosApiFp(configuration).updateVideo(body, id, options)(fetch, basePath);
        },
        /**
         * SimpleSaml auth required
         * @summary Upload video thumbnail
         * @param {any} file 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideoThumbnail(file: any, id: string, options?: any) {
            return VideosApiFp(configuration).uploadVideoThumbnail(file, id, options)(fetch, basePath);
        },
    };
};

/**
 * VideosApi - interface
 * @export
 * @interface VideosApi
 */
export interface VideosApiInterface {
    /**
     * 
     * @summary Create a new video
     * @param {CreateVideoDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApiInterface
     */
    createVideo(body: CreateVideoDto, options?: any): Promise<VideoDto>;

    /**
     * 
     * @summary Delete a video
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApiInterface
     */
    deleteVideo(id: string, options?: any): Promise<{}>;

    /**
     * SimpleSaml auth required
     * @summary Get a delegated video upload token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApiInterface
     */
    getDelegatedToken(options?: any): Promise<UploadTokenDto>;

    /**
     * 
     * @summary Get a video
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApiInterface
     */
    getVideo(id: string, options?: any): Promise<VideoDto>;

    /**
     * 
     * @summary Update a video
     * @param {UpdateVideoDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApiInterface
     */
    updateVideo(body: UpdateVideoDto, id: string, options?: any): Promise<VideoDto>;

    /**
     * SimpleSaml auth required
     * @summary Upload video thumbnail
     * @param {any} file 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApiInterface
     */
    uploadVideoThumbnail(file: any, id: string, options?: any): Promise<VideoDto>;

}

/**
 * VideosApi - object-oriented interface
 * @export
 * @class VideosApi
 * @extends {BaseAPI}
 */
export class VideosApi extends BaseAPI implements VideosApiInterface {
    /**
     * 
     * @summary Create a new video
     * @param {CreateVideoDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public createVideo(body: CreateVideoDto, options?: any) {
        return VideosApiFp(this.configuration).createVideo(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a video
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public deleteVideo(id: string, options?: any) {
        return VideosApiFp(this.configuration).deleteVideo(id, options)(this.fetch, this.basePath);
    }

    /**
     * SimpleSaml auth required
     * @summary Get a delegated video upload token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public getDelegatedToken(options?: any) {
        return VideosApiFp(this.configuration).getDelegatedToken(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a video
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public getVideo(id: string, options?: any) {
        return VideosApiFp(this.configuration).getVideo(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update a video
     * @param {UpdateVideoDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public updateVideo(body: UpdateVideoDto, id: string, options?: any) {
        return VideosApiFp(this.configuration).updateVideo(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * SimpleSaml auth required
     * @summary Upload video thumbnail
     * @param {any} file 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public uploadVideoThumbnail(file: any, id: string, options?: any) {
        return VideosApiFp(this.configuration).uploadVideoThumbnail(file, id, options)(this.fetch, this.basePath);
    }

}
