import React from 'react';
import { Button, Card, CardContent, Flag, Typography } from './Common';
import { App, Region, Station } from '../apis/_generated/listenapi';
import { FileCopy } from '@material-ui/icons';
import { EventsShuttlelinksDto } from '../apis/_generated/livestream';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { grey } from '@material-ui/core/colors';

interface ShuttleLinksInterface {
    regions: Region[];
    stations: Station[];
    applications: App[];
    shuttleLinks: EventsShuttlelinksDto[];
}

const ShuttleLinks = (props: ShuttleLinksInterface) => {

    const getRegionFromId = (regionId: number): Region | undefined => {
        return props.regions.find((region) => region.StationRegionId === regionId);
    }

    const getAppFromId = (appId: number): App | undefined => {
        return props.applications.find((app) => app.AppId === appId);
    }

    const getStationFromId = (stationId: number): Station | undefined => {
        return props.stations.find((station) => station.stationId === stationId);
    }

    const [openWithId, setOpenWithId] = React.useState(0);

    const handleTooltipClose = () => {
        setOpenWithId(0);
    };

    const handleTooltipOpen = (id: number) => {
        setOpenWithId(id);
    };

    return (<Card>
        <CardContent>
            <Typography variant='h6' gutterBottom>
                ShuttleLinks
            </Typography>
            <Typography variant="body2" gutterBottom>
                They are generated as soon as the event is saved.
            </Typography>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Region</TableCell>
                            <TableCell>Application</TableCell>
                            <TableCell>Station</TableCell>
                            <TableCell>ShuttleLink</TableCell>
                        <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.shuttleLinks.map(shuttleLink => {
                        const region = getRegionFromId(shuttleLink.regionId);
                        const app = getAppFromId(shuttleLink.appId);
                        const station = getStationFromId(shuttleLink.stationId);

                        const flag = `/static/img/flags/${region?.StationRegionCode?.toLowerCase()}.png`;

                        return (<TableRow key={shuttleLink.id}>
                            <TableCell>
                                <Flag src={flag} alt={region?.StationRegionName} title={region?.StationRegionName} /> {region?.StationRegionName}
                            </TableCell>
                            <TableCell>
                                <div style={{display: "flex", alignItems: "center" }}>
                                <img
                                    src={app?.AppIconImageUrl || app?.AppIconImageUrl}
                                    alt={app?.AppCode}
                                    style={{
                                        width: '3em',
                                        marginRight: '0.3em'
                                    }}    />
                                {app?.AppName}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div style={{display: "flex", alignItems: "center" }}>
                                <img src={station?.stationListenBarLogo}
                                         alt={station?.stationCode}
                                         style={{
                                             width: '3em',
                                             marginRight: '0.3em'
                                         }} /> {station?.stationName}
                                </div>
                            </TableCell>
                            <TableCell>
                                <code>{shuttleLink.shuttlelinkUrl}</code>
                            </TableCell>
                            <TableCell>

                                    <CopyToClipboard text={shuttleLink.shuttlelinkUrl} onCopy={() => handleTooltipOpen(shuttleLink.id)}>
                                        <Tooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            onClose={handleTooltipClose}
                                            open={openWithId === shuttleLink.id}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title="Copied"
                                            placement="top"
                                            onMouseLeave={() => setOpenWithId(0)}
                                        >
                                            <Button style={{ color: openWithId === shuttleLink.id ? grey[200] : grey[500] }}>
                                                <FileCopy fontSize="small" style={{ marginRight: '0.3em' }} />
                                                Copy to clipboard
                                            </Button>
                                        </Tooltip>
                                    </CopyToClipboard>
                            </TableCell>
                        </TableRow>)
                    })}
                    </TableBody>
                </Table>
            </TableContainer>

        </CardContent>
    </Card>);
}

export default ShuttleLinks;
