import * as Yup from 'yup';
import { EventCreateDto } from '../../apis/_generated/livestream';

const always: Partial<Record<keyof EventCreateDto | 'regionId', any>> = {
    // @note Lee: slug CAN be updated, like with other systems
    slug: Yup.string().matches(/[a-zA-Z0-9-]+/, {
        message: "Must contain only abc, ABC, 123, or -"
    }),

    title: Yup.string().required("Required"),
    livePopupTitle: Yup.string().nullable()
        .when("isLivePopupEnabled", {
            is: true,
            then: Yup.string().required("Required")
        }),
    livePopupDescription: Yup.string().nullable()
        .when("isLivePopupEnabled", {
            is: true,
            then: Yup.string().required("Required")
        }),
    livePopupButtonText: Yup.string().nullable()
        .when("isLivePopupEnabled", {
            is: true,
            then: Yup.string().required("Required")
        }),
    description: Yup.string(),
    highlightColour: Yup.string().matches(/#[0-9A-Fa-f]{6}/, {
        message: "Enter a full hex code, example: #ff0000"
    }).required("Required"),

    // Dates
    startAt: Yup.date().required("Required").default(() => new Date()),
    endAt: Yup.date().required("Required").default(() => new Date())
        .when('startAt', (startAt: Date, schema: any) => {
            return startAt && schema.min(startAt, "Must be after start date")
        }),

    liveStartAt: Yup.date().nullable()
        .when("eventType", {
            is: EventCreateDto.EventTypeEnum.Livestream,
            then: Yup.date().required("Required").nullable().default(() => new Date())
        })
        .when('startAt', (startAt: Date, schema: any) => {
            return startAt && schema.min(startAt, 'Must be between the marketing start / end date')
        })
        .when('endAt', (endAt: Date, schema: any) => {
            return endAt && schema.max(endAt, 'Must be between the marketing start / end date')
        }),
    liveEndAt: Yup.date().nullable()
        .when("eventType", {
            is: EventCreateDto.EventTypeEnum.Livestream,
            then: Yup.date().required("Required").nullable().default(() => new Date())
                .when('liveStartAt', (liveStartAt: Date, schema: any) => {
                    return liveStartAt ? schema.min(liveStartAt, "Must be after start date") : schema;
                })
        }),

    // Strings (URLs / Images)
    imageHeroSmall: Yup.string().url().required("Required"),
    imageHeroLarge: Yup.string().url().required("Required"),
    livePopupImageHero: Yup.string().nullable()
        .when("isLivePopupEnabled", {
            is: true,
            then: Yup.string().url().required("Required")
        }),
    livePopupButtonUrl: Yup.string().matches(/^(http|app)/, {
        message: "Only http or app urls are supported (http://.. or app___://..)"
    }).nullable()
        .when("isLivePopupEnabled", {
            is: true,
            then: Yup.string().required("Required")
        }),

    // Enums (Selects)
    eventType: Yup.mixed().oneOf(Object.values(EventCreateDto.EventTypeEnum)),

    // Arrays (Multi-selects)
    stationIds: Yup.array()
        .test(
            'oneOfRequired',
            'At least 1 Station or 1 App is required',
            function(item) {
                return (this.parent.appIds.length || this.parent.stationIds.length)
            }
        ),
    appIds: Yup.array()
        .test(
            'oneOfRequired',
            'At least 1 Station or 1 App is required',
            function(item) {
                return (this.parent.appIds.length || this.parent.stationIds.length)
            }
        ),

    // Regions: API supports many; UI supports one
    regionId: Yup.number().min(1, 'Required'),

    // Booleans
    isLoginRequired: Yup.boolean().required("Required"),
    isFeaturedEvent: Yup.boolean().required("Required"),
    isPremiumOnly: Yup.boolean().required("Required"),
    isLivePopupEnabled: Yup.boolean().required("Required"),
};


// @note Originally these forms handled validation a little differently (slug was create-only)
// Leaving here as an example of how to avoid "optional|sometimes" validation
export const EventCreateFormValidator = Yup.object().shape({
    ...always,
    //...onCreationOnly
});
export const EventUpdateFormValidator = Yup.object().shape({
    ...always
});
