import { LiveStreamsDto } from '../apis/_generated/livestream';

export const LiveStreamOrientations:  {
  type: LiveStreamsDto.OrientationEnum,
  label: string
}[] = [
  {
    type: LiveStreamsDto.OrientationEnum.Landscape,
    label: "Landscape"
  }, {
    type: LiveStreamsDto.OrientationEnum.Portrait,
    label: "Portrait"
  }
];

export const getLiveStreamOrientationLabel = (type: LiveStreamsDto.OrientationEnum): string => {
  const status = LiveStreamOrientations.find((item) => item.type === type) ?? LiveStreamOrientations[0];
  return status.label;
}
