import * as types from '../../constants';
import { SetConfigType, ConfigInterface } from '../actions/configActions';

export type ConfigInitialStateType = {
  config: ConfigInterface;
};

const initialState = {
  config: {} as ConfigInterface,
};

export default function reducer(
  state = initialState,
  actions: ActionTypes
): ConfigInitialStateType {
  switch (actions.type) {
    case types.CONFIG_SET:
      return {
        ...state,
        config: actions.payload,
      };

    default:
      return state;
  }
}

type ActionTypes = SetConfigType;
