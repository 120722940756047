import React, { useEffect, useState } from 'react';
import { EventUpdateFormValidator } from './Validator';
import { EventForm, SaveableEvent } from './EventForm';
import { FormikHelpers } from 'formik/dist/types';
import { EventManagerService } from '../../services/eventManagerService';
import { useSelector } from 'react-redux';
import { AppStateType } from '../../redux/reducers';
import { EventOutDto } from '../../apis/_generated/livestream';

interface EventEditPageQuery {
    id: number,
}

/**
 * Full events list page
 * @param props
 * @constructor
 */
const EventEditPage = (props: {
    match: {
        params: EventEditPageQuery
    }
}) => {
    const { config } = useSelector((state: AppStateType) => state.configReducer);
    const [eventManagerService] = useState<EventManagerService>(new EventManagerService({ basePath: config.resources.livestream.endpoint }));
    const [event, setEvent] = useState<EventOutDto & {
        regionId: number
    }>();

    useEffect(() => {
        fetchEvent(props.match.params);
    }, [props]);

    /**
     * Retrieves specified event
     * @param props
     */
    const fetchEvent = async (props: EventEditPageQuery) => {
        const event = await eventManagerService.getEvent(props.id);
        setEvent({
            ...event,
            regionId: event.regionIds[0]
        });
    };

    /**
     * Update event
     */
    const handleSubmit = (eventValues: SaveableEvent, formikHelpers: FormikHelpers<SaveableEvent>): void | Promise<EventOutDto> => {
        if(!eventValues.id || !event) return;

        // @ts-ignore
        return eventManagerService.updateEvent({
            ...eventValues,
            regionIds: [ eventValues.regionId ],
        }, eventValues.id)
            .then((event) => {
                setTimeout(async () => {
                    await fetchEvent({ id: event.id });
                }, 500);
                return event;
            });
    }


    return !event ? null : (
        <EventForm
            event={event}
            validator={EventUpdateFormValidator}
            onSubmit={handleSubmit}
            shuttleLinks={event.eventsShuttlelinks}
        />
    );
};


export default EventEditPage;
