import * as types from "../../constants";
import { AppDispatchType } from "../store";
import { getAuthedUser } from "../../services/authService";
import { UserDto } from "../../apis/_generated/livestream";
import { ConfigInterface } from "./configActions";

export function signIn(config: ConfigInterface) {
    return async (dispatch: AppDispatchType) => {
        dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

        return getAuthedUser(config)
            .then((user: UserDto) => {
                dispatch({
                    type: types.AUTH_SIGN_IN_SUCCESS,
                    id: user.id?.toString(),
                    email: user.email,
                    name: `${user.firstName} ${user.lastName}`,
                    isAdmin: user.roles.includes('admin')
                });
            })
            .catch((error) => {
                dispatch({ type: types.AUTH_SIGN_OUT });
            });
    };
}

export function signOut() {
    return async (dispatch: AppDispatchType) => {
        dispatch({
            type: types.AUTH_SIGN_OUT,
        });
    };
}
