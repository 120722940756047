import React, { ChangeEvent, useEffect, useState } from 'react';
import { LiveStreamCreateDto, LiveStreamsDto } from '../../../apis/_generated/livestream';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    LinearProgress,
    makeStyles,
    Radio,
    RadioGroup,
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    Button,
} from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { EventManagerService } from '../../../services/eventManagerService';
import { useFormik } from 'formik';
import { LiveStreamFormValidator } from './Validator';
import { Alert, Box, Divider, Grid, IconButton, TextField, Typography } from '../../../components/Common';
import { Save as SaveIcon, Close as CloseIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { AppStateType } from '../../../redux/reducers';
import { LiveStreamOrientations } from '../../../constants/liveStreamOrientations';
import { LiveStreamStatuses } from '../../../constants/liveStreamStatuses';
import { TextFieldWithCopyComponent } from '../../../components/CopyComponent';

export type SaveableLiveStream = Partial<LiveStreamsDto>;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleBar: {
            padding: theme.spacing(0),
        },
        title: {
            padding: theme.spacing(4, 4, 4),
        },
        container: {
            padding: theme.spacing(0, 5, 5),
        },
        thumbnailContainer: {
            textAlign: 'center'
        },
    }),
);

export interface LiveStreamDialogProps {
    eventId: number,
    open: boolean,
    handleClose: () => void,
    liveStreamList: LiveStreamsDto[] | undefined,
    updateLiveStreamList: (liveStreamDto: LiveStreamsDto) => void,
    liveStream: SaveableLiveStream
}

const LiveStreamDialog = (props: LiveStreamDialogProps) => {
    const { config } = useSelector((state: AppStateType) => state.configReducer);
    const eventManagerService = new EventManagerService({ basePath: config.resources.livestream.endpoint });
    const classes = useStyles();

    const [liveStreamSaving, setLiveStreamSaving] = useState(false);
    const [liveStreamSavingError, setLiveStreamSavingError] = useState('');

    const [thumbnail, setThumbnail] = useState(props.liveStream.assets?.thumbnail);
    const [thumbnailUploadInProgress, setThumbnailUploadInProgress] = useState(false);
    const [thumbnailUploadFinished, setThumbnailUploadFinished] = useState(false);
    const [thumbnailUploadError, setThumbnailUploadError] = useState('');

    const thumbnailReset = () => {
        setThumbnailUploadError('');
        setThumbnailUploadFinished(false);
        setThumbnailUploadInProgress(false);
    }

    // Every time Dialog opens reset livestream upload values
    useEffect(() => {
        setLiveStreamSaving(false);
        setLiveStreamSavingError('');
    }, [props.open])
    useEffect(() => {
        if (props.liveStream.assets?.thumbnail) setThumbnail(props.liveStream.assets.thumbnail);
        thumbnailReset();
    }, [props.liveStream.assets?.thumbnail])

    const handleSubmit = (values: SaveableLiveStream) => {
        values.eventId = props.eventId;
        setLiveStreamSaving(true);

        const response = values.id
            ? eventManagerService.updateLivestream(values, values.id)
            : eventManagerService.createLivestream(values as LiveStreamCreateDto);

        // Update form's state with the latest information
        response
            .then(async (liveStreamDto: LiveStreamsDto) => {
                props.updateLiveStreamList(await liveStreamDto);
            })
            .catch((error) => {
                setLiveStreamSavingError('LiveStream failed to save: ' + (error.message ?? error.statusText));
            })
            .finally(() => {
                props.handleClose();
            });
    }

    const handleThumbnailUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        thumbnailReset();
        if (!props.liveStream.id) {
            setThumbnailUploadError('No id associated with thumbnail');
            return;
        }
        if (!event.currentTarget.files?.[0]) {
            setThumbnailUploadError('No thumbnail to upload');
            return;
        }

        setThumbnailUploadInProgress(true);

        const formData = new FormData()
        formData.append('file', event.currentTarget.files[0])
        /**
         * The client generate via swagger-codegen3 cannot cope with uploading files.
         * It uploads as the wrong content-type, `application/x-www-form-urlencoded`
         * when it need to be `multipart/form-data`.
          */
        await fetch(
            `${config.resources.livestream.endpoint}/api/v1/livestreams/thumbnail/${props.liveStream.id}`,
            {
                method: 'POST',
                body: formData
            })
            .then(async (response) => {
                setThumbnailUploadFinished(true);
                const responseJson = await response.json();
                if (response.status >= 200 && response.status < 300) {
                    setThumbnail(URL.createObjectURL(formData.get('file')));
                    if (props.liveStreamList) {
                        props.updateLiveStreamList(responseJson);
                    }
                } else {
                    setThumbnailUploadError(responseJson.message);
                }
            })
            .catch((error) => {
                setThumbnailUploadError(error.message);
            })
            .finally(() => {
                setThumbnailUploadInProgress(false);
            })
    }

    const form = useFormik({
        initialValues: props.liveStream,
        validationSchema: LiveStreamFormValidator,
        onSubmit: handleSubmit,
        enableReinitialize: true, // As we are reusing this modal for all the livestreams on the page we need this
    });

    return (
        <MuiDialog open={props.open} onClose={props.handleClose} maxWidth={"md"} fullScreen={false}>
            <MuiDialogTitle disableTypography className={classes.titleBar}>
                <Box display="flex" p={1}>
                    <Box p={1} flexGrow={1}>
                        <Typography variant="h3" className={classes.title}>
                            { form.values.id ? 'Edit ' : 'New ' } LiveStream
                        </Typography>
                    </Box>
                    <Box p={1} order={3}>
                        <IconButton aria-label="close" color="primary" onClick={props.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </MuiDialogTitle>
            <Grid container className={classes.container}>
                <Grid item xs={12} md={12}>
                    <form onSubmit={form.handleSubmit}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={4}>
                                    {form.values.id && (<Grid item xs={12}>
                                        <TextField
                                            label='ID'
                                            value={form.values.id}
                                            fullWidth
                                            disabled
                                            variant='outlined'
                                        />
                                    </Grid>)}
                                    <Grid item xs={12}>
                                        <TextField
                                            id='name'
                                            name='name'
                                            label='Name'
                                            value={form.values.name}
                                            autoFocus
                                            error={Boolean(form.touched.name && form.errors.name)}
                                            helperText={form.touched.name && form.errors.name}
                                            onBlur={form.handleBlur}
                                            onChange={form.handleChange}
                                            fullWidth
                                            variant='outlined'
                                        />
                                    </Grid>
                                    {form.values.id && (<Grid item xs={12}>
                                        <Grid container spacing={2}>

                                            <Grid item xs={12}>
                                                <Accordion>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h6">Broadcast info</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={2}>

                                                            <Grid item xs={12}>
                                                                <TextFieldWithCopyComponent
                                                                    label='Live stream ID'
                                                                    value={form.values.liveStreamId}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextFieldWithCopyComponent
                                                                    label='Stream Key'
                                                                    value={form.values.streamKey}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextFieldWithCopyComponent
                                                                  label='Player URL'
                                                                  value={form.values.assets?.player}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextFieldWithCopyComponent
                                                                  label='HLS'
                                                                  value={form.values.assets?.hls}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Divider my={3} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container justify='center' spacing={4}>
                                                    <Grid id='thumbnail' item xs={12} sm={6}>
                                                        <Typography variant="h4" gutterBottom>
                                                            Video Thumbnail (4:3, 500px min, jpeg)
                                                        </Typography>
                                                        <Card variant="outlined">
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}
                                                                      className={classes.thumbnailContainer}>
                                                                    <img
                                                                        src={!thumbnail ? '/static/img/video/no-thumbnail.png' : thumbnail}
                                                                        alt={form.values.name}
                                                                        style={{
                                                                            backgroundImage: 'url("/static/img/video/no-thumbnail.png")',
                                                                            backgroundSize: 'cover',
                                                                            backgroundPosition: 'center',
                                                                            maxWidth: '100%',
                                                                            height: '300px',
                                                                            display: 'block',
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} padding={4}>
                                                                    <input
                                                                        accept="image/jpeg"
                                                                        id="thumbnail"
                                                                        name="thumbnail"
                                                                        type="file"
                                                                        onChange={handleThumbnailUpload}
                                                                    />
                                                                    {thumbnailUploadInProgress && (
                                                                        <Grid item xs={12} paddingTop={4}>
                                                                            <LinearProgress/>
                                                                        </Grid>
                                                                    )}
                                                                    {thumbnailUploadFinished && !thumbnailUploadError && (
                                                                        <Grid item xs={12} paddingTop={4}>
                                                                            <Alert severity="success">Thumbnail
                                                                                successfully uploaded</Alert>
                                                                        </Grid>
                                                                    )}
                                                                    {thumbnailUploadError && (
                                                                        <Grid item xs={12} paddingTop={4}>
                                                                            <Alert
                                                                                severity="error">{thumbnailUploadError}</Alert>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    </Grid>
                                                    <Grid id='livestream' item xs={12} sm={6}>
                                                        <Typography variant="h4" gutterBottom>LiveStream Video</Typography>
                                                        <Card>
                                                            <Grid xs={12}>
                                                                {form.values.assets?.iframe && (<div style={{ height: '350px' }} dangerouslySetInnerHTML={{__html: form.values.assets?.iframe }} />)}
                                                            </Grid>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>)}
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Video orientation</FormLabel>
                                            <RadioGroup
                                                aria-label="Orientation"
                                                name="Orientation"
                                                value={form.values.orientation}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                row
                                            >
                                                {LiveStreamOrientations.map((item, index) => (
                                                  <FormControlLabel
                                                    key={index}
                                                    onChange={async () => await form.setFieldValue('orientation', item.type)}
                                                    value={item.type} control={<Radio/>} label={item.label}/>
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Record</FormLabel>
                                            <RadioGroup
                                              aria-label="Record"
                                              name="Record"
                                              value={form.values.record?.toString()}
                                              onBlur={form.handleBlur}
                                              row
                                            >
                                                {LiveStreamStatuses.map((item, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        onChange={() => form.setFieldValue('record', item.type)}
                                                        value={item.type.toString()} control={<Radio/>} label={item.label} />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>


                                    {/* @see AS-4792 Comment this out while API video don't support 3k+ users in private lives
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Public</FormLabel>
                                            <RadioGroup
                                              aria-label="Public"
                                              name="Public"
                                              value={form.values._public?.toString()}
                                              onBlur={form.handleBlur}
                                              row
                                            >
                                                {LiveStreamStatuses.map((item, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        onChange={() => form.setFieldValue('_public', item.type)}
                                                        value={item.type.toString()} control={<Radio/>} label={item.label}/>
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    */}

                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Grid container spacing={4}>
                                    <Grid item sm={6}>
                                        <Button variant="contained" color="secondary" onClick={props.handleClose}>Cancel</Button>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Grid container justify='flex-end'>
                                            {!liveStreamSaving && (
                                                <Button type='submit' variant="contained" color="primary" startIcon={<SaveIcon/>}>
                                                    Save
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {liveStreamSaving && (
                                        <Grid item xs={12} paddingTop={4}>
                                            <LinearProgress/>
                                        </Grid>
                                    )}
                                    {liveStreamSavingError && (
                                        <Grid item xs={12} paddingTop={4}>
                                            <Alert severity="error">{liveStreamSavingError}</Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardActions>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </MuiDialog>
    );
};

export default LiveStreamDialog;
