import * as React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useDispatch, useSelector } from "react-redux";

import {
    Tooltip,
    Menu,
    MenuItem,
    IconButton as MuiIconButton,
} from "@material-ui/core";

import { signOut } from "../redux/actions/authActions";
import { AppStateType } from "../redux/reducers";

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

function UserDropdown() {
    const { config } = useSelector((state: AppStateType) => state.configReducer);
    const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
    const dispatch = useDispatch();

    const toggleMenu = (event: React.SyntheticEvent) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const handleSignOut = async () => {
        await dispatch(signOut());
    };

    function handleProfile() {
        window.open(config.sso.profileUrl);
    }

    return (
        <React.Fragment>
            <Tooltip title="Account">
                <IconButton
                    aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    color="inherit"
                >
                    <Power />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
            >
                <MenuItem onClick={handleProfile}>Profile</MenuItem>
                <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export default UserDropdown;
