import React, { useState } from 'react';
import { Alert, Button, Card, CardContent, Grid, Typography } from '../../../components/Common';
import { VideoDto } from '../../../apis/_generated/livestream';
import { CardActions, CardMedia, LinearProgress, Link, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ViewIcon from '@material-ui/icons/Movie';
import { Add as AddIcon } from '@material-ui/icons';
import { EventManagerService } from '../../../services/eventManagerService';
import { VideoLoginRequiredEnum } from '../../../constants/videoLoginRequired';
import VideoDialog, { SaveableVideo } from './VideoDialog';
import { createStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { AppStateType } from '../../../redux/reducers';

interface EventVideoListProps {
    eventId: number,
    videos?: VideoDto[],
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        thumbnailContainer: {
            textAlign: 'center'
        },
    }),
);

const VideoList = (props: EventVideoListProps) => {
    const { config } = useSelector((state: AppStateType) => state.configReducer);
    const classes = useStyles();
    const blankVideo: SaveableVideo = {
        loginRequired: VideoLoginRequiredEnum.no,
        metadata: [
            {
                key: 'eventId',
                value: props.eventId.toString()
            },
            {
                key: 'loginRequired',
                value: 'no'
            }
        ]
    };
    const [editVideo, setEditVideo] = useState({});
    const [videosList, setVideosList] = useState<VideoDto[] | undefined>(props.videos);

    const [videosRefreshing, setVideosRefreshing] = useState(false);
    const [videosError, setVideosError] = useState('');

    const handleDelete = (videoId: string) => () => {
        const eventManagerService = new EventManagerService({ basePath: config.resources.livestream.endpoint });
        eventManagerService.deleteVideo(videoId)
            .then((video) => {
                if (videosList === undefined) {
                    return;
                }
                setVideosList(videosList.filter((o) => o.videoId !== videoId));
            })
            .catch((error) => {
                setVideosError('Video failed to delete: ' + (error.message ?? error.statusText));
            });
    };

    const [openDialog, setOpenDialog] = useState(false);
    // function to handle modal open
    const handleOpen = (video: SaveableVideo) => () => {
        // Move loginRequired from the values.metadata array into values.loginRequired
        if (video.metadata) {
            // Default loginRequired to no
            let loginRequired = VideoLoginRequiredEnum['no'];
            // -1 if not found else the index of the first element that matches
            const loginRequiredIndex = video.metadata.findIndex((o) => o.key === 'loginRequired');
            if (-1 !== loginRequiredIndex) {
                // @ts-ignore
                loginRequired = VideoLoginRequiredEnum[video.metadata[loginRequiredIndex].value];
            }
            video.loginRequired = loginRequired;
        }
        if (video.assets?.thumbnail) {
            video.thumbnail = video.assets.thumbnail;
        }

        setEditVideo(video);
        setOpenDialog(true);
    };
    // function to handle modal close
    const handleClose = () => {
        setEditVideo(blankVideo);
        setOpenDialog(false);
    };

    const updateVideoList = (videoDto: VideoDto) => {
        if (!videosList) {
            setVideosList([videoDto]);
        } else {
            setVideosRefreshing(true);
            const editedVideoIndex = videosList.findIndex((o) => o.videoId === videoDto.videoId)
            if (-1 !== editedVideoIndex) {
                videosList[editedVideoIndex] = videoDto;
            } else {
                // add new video
                videosList.push(videoDto);
            }
            setVideosList(videosList.filter((o) => true));
        }

        setVideosRefreshing(false);
    }

    return (<Grid container spacing={3} id="videoList">
        <Grid item spacing={6} xs={12}>
            <Button
                startIcon={<AddIcon/>}
                size='large'
                variant='contained'
                color='primary'
                mb={3}
                onClick={handleOpen(blankVideo)}>
                New Video
            </Button>
        </Grid>
        {videosRefreshing && (
        <Grid item xs={12} paddingTop={4}>
            <LinearProgress color="secondary" />
        </Grid>
        )}
        {videosError && (
            <Grid item xs={12} paddingTop={4}>
                <Alert severity="error">{videosError}</Alert>
            </Grid>
        )}
        {videosList && (
            videosList.map((video: VideoDto) => (
                <Grid key={video.videoId} item spacing={6} xs={12} sm={6} lg={4}>
                    <Card variant="outlined">
                        <Grid container spacing={0}>
                            <Grid item xs={6} className={classes.thumbnailContainer}>
                                <img
                                    src={!video.assets.thumbnail ? '/static/img/video/no-thumbnail.png' : video.assets.thumbnail}
                                    alt={video.videoId}
                                    style={{height: 'auto', maxWidth: '100%', maxHeight: '200px'}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {!video.assets.mp4 ?
                                    <CardMedia
                                        image={'/static/img/video/no-video.png'}
                                        title={'No video'}
                                        style={{height: 0, paddingTop: '62.5%'}}
                                    />
                                    : <CardMedia
                                        component='video'
                                        src={video.assets.mp4}
                                        title={video.title}
                                        controls
                                    />
                                }
                            </Grid>
                        </Grid>
                        <CardContent>
                            <Typography variant="subtitle1">{!video.title ? 'title not set' : video.title}</Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={handleDelete(video.videoId)} startIcon={<DeleteIcon/>} variant="contained" color="secondary" style={{marginRight: 'auto'}}>Delete</Button>
                            <Link href={video.assets.mp4} style={{marginLeft: 'auto', marginRight: 'auto'}}
                                  target='_blank'><Button variant="outlined" color="secondary"
                                                          startIcon={<ViewIcon/>}>View</Button></Link>
                            <Button onClick={handleOpen(video)} variant="contained" color="primary" style={{marginLeft: 'auto'}} startIcon={<EditIcon/>}>Edit</Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))
        )}

        <VideoDialog video={editVideo} open={openDialog} handleClose={handleClose} setVideos={setVideosList} videosList={videosList} updateVideoList={updateVideoList}/>
    </Grid>);
};

export default VideoList;
